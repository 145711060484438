/**
 * Exports use ctk news actions and send requests to api/news
 * having own reducer is just for maintain exports config/callbacks
 */
import { ACTIONS, AGENCIES } from '../constants/News'
import _ from 'lodash'

export function getInitialState () {
  return {
    isExporting: false,
    lastExportedNews: {},
    lastExportedResult: null,
    data: loadExportFromGlobalConfig()
  }
}

function loadExportFromGlobalConfig (defaultConfig = []) {
  const returnObject = {}
  if ((typeof cia_config !== 'undefined') && _.isObject(cia_config.export)) {
    for (let agency in cia_config.export) {
      if (cia_config.export.hasOwnProperty(agency)) {
        const lcAgencies = []
        for (let agency in Object.keys(AGENCIES)) {
          lcAgencies.push(agency.toLowerCase())
        }
        if (lcAgencies.indexOf(agency.toLowerCase())) {
          if (_.some(cia_config.export[agency], (exportData) => {
            return !_.isFunction(exportData.callback)
          })) {
            returnObject[AGENCIES[agency.toUpperCase()]] = defaultConfig.hasOwnProperty(agency) ? defaultConfig[agency] : []
          } else {
            returnObject[AGENCIES[agency.toUpperCase()]] = cia_config.export[agency]
          }
        }
      }
    }
  }
  return returnObject
}

export default function exports (state = getInitialState(), action) {
  switch (action.type) {
    case ACTIONS.REQUEST_EXPORT:
      return Object.assign({}, state, {
        isExporting: true
      })
    case ACTIONS.RECEIVE_EXPORT:
      return Object.assign({}, state, {
        isExporting: false,
        lastExportedNews: action.news,
        lastExportedResult: 'ok'
      })
    case ACTIONS.RECEIVE_EXPORT_FAILED:
      return Object.assign({}, state, {
        isExporting: false,
        lastExportedNews: action.news,
        lastExportedResult: action.reason
      })
    default:
      return state
  }
}
