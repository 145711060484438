import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ExportLogItem from './ExportLogItem'

export default class ExportLog extends Component {
  render () {
    const {
      dispatch,
      exports,
      news
    } = this.props

    return (
      <table className='table table-striped detailinfo'>
        <thead>
          <tr>
            <th colSpan={5}>
              Exporty do systémů
            </th>
          </tr>
        </thead>
        <tbody>
          {
            exports.map((item, index) => (
              <ExportLogItem dispatch={dispatch} news={news} item={item} key={index}/>
            ))
          }
        </tbody>
      </table>
    )
  }
}

ExportLog.propTypes = {
  dispatch: PropTypes.func.isRequired,
  exports: PropTypes.array,
  news: PropTypes.object
}
