import React, { Component } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { LAYOUT_FLAGS } from '../../constants/Layout'
import Highlightable from '../Highlightable'
import { NEWS_TYPES_CTK } from '../../constants/News'

export default class ItemCtk extends Component {
  render () {
    const {
      exports,
      layout,
      loadDetail,
      news
    } = this.props

    const date = moment(news.updated)
    let newsStyles = 'col-sm-12 table-row news-row'

    if (Array.isArray(news.keywords)) {
      news.keywords.forEach(keyword => {
        if (keyword.includes(NEWS_TYPES_CTK.HEADLINE)) {
          newsStyles += ' headline'
        }
        if (keyword.includes(NEWS_TYPES_CTK.FLASH)) {
          newsStyles += ' flash'
        }
        if (keyword.includes(NEWS_TYPES_CTK.PROTEXT)) {
          newsStyles += ' protext'
        }
        if (keyword.includes(NEWS_TYPES_CTK.ADVICE)) {
          newsStyles += ' avizo'
        }
        if (keyword.includes(NEWS_TYPES_CTK.VERSION)) {
          newsStyles += ' verze'
        }
        if (keyword.includes(NEWS_TYPES_CTK.PHOTO)) {
          newsStyles += ' photo'
        }
      })
    }

    return (
      <div className={newsStyles} onClick={() => loadDetail(news.unique_id)}>
        <div className='col-sm-10 col-lg-10 table-cell news-cell'>
          <div className={`datetime ${(layout.mask & LAYOUT_FLAGS.DETAIL_POSITION) ? 'time-narrow' : 'time-wide'}`}>
            { moment(date).format('DD.MM. HH:mm:ss') }&nbsp;( {moment(date).locale('cs').fromNow()} )
          </div>
          <div>
            <Highlightable highlighted={news.title_highlighted} normal={news.title}/>
          </div>
        </div>
        <div className='news-icon-box col-sm-2 col-lg-2 table-cell news-cell'>
          {
            Array.isArray(news.keywords) &&
            news.keywords.map((keyword, index) => {
              if (keyword.includes(NEWS_TYPES_CTK.HEADLINE)) {
                return (
                  <span className='hl news-icon-span' title='headline' key={index}>
                    headline
                  </span>
                )
              }
              if (keyword.includes(NEWS_TYPES_CTK.FLASH)) {
                return (
                  <span className='fl news-icon-span' title='fleš' key={index}>
                    fleš
                  </span>
                )
              }
              if (keyword.includes(NEWS_TYPES_CTK.PROTEXT)) {
                return (
                  <span className='pt news-icon-span' title='Protext' key={index}>
                    protext
                  </span>
                )
              }
              if (keyword.includes(NEWS_TYPES_CTK.ADVICE)) {
                return (
                  <span className='av news-icon-span' title='Avízo' key={index}>
                    avízo
                  </span>
                )
              }
              if (keyword.includes(NEWS_TYPES_CTK.VERSION)) {
                return (
                  <span className='vz news-icon-span' title='{version}' key={index}>
                    { keyword.toLowerCase() }
                  </span>
                )
              }
              if (keyword.includes(NEWS_TYPES_CTK.PHOTO)) {
                return (
                  <span
                    className={'glyphicon glyphicon-camera news-icon-span ph news-photo'}
                    title='Zpráva obsahuje fotografii'
                    key={index}
                  />
                )
              }
            })
          }
          { exports }
        </div>
      </div>
    )
  }
}

ItemCtk.propTypes = {
  news: PropTypes.object,
  loadDetail: PropTypes.func,
  layout: PropTypes.object
}
