import React, { Component } from 'react'
import PropTypes from 'prop-types'
import CtkDetail from './Ctk'
import ReutersDetail from './Reuters'
import ReutersPhotosDetail from './ReutersPhotos'
import { LAYOUT_FLAGS } from '../../constants/Layout'
import { AGENCIES } from '../../constants/News'

class NewsDetail extends Component {
  render () {
    const {
      layout,
      detail,
      loadRelatedDetail,
      detailAgency
    } = this.props

    if (!(layout.mask & LAYOUT_FLAGS.DETAIL) || Object.keys(detail).length === 0) {
      return null
    }

    let DetailComponent

    switch (detailAgency) {
      case AGENCIES.REUTERS_PHOTOS:
        DetailComponent = ReutersPhotosDetail
        break
      case AGENCIES.REUTERS:
        DetailComponent = ReutersDetail
        break
      case AGENCIES.CTK:
      default:
        DetailComponent = CtkDetail
    }

    if (!detail) {
      return null
    }

    return (
      <DetailComponent
        detail={detail}
        detailAgency={detailAgency}
        key={detail.unique_id}
        layout={layout}
        loadDetail={detailAgency === AGENCIES.CTK ? loadRelatedDetail : null}
      />
    )
  }
}

NewsDetail.propTypes = {
  layout: PropTypes.object,
  detail: PropTypes.object,
  detailAgency: PropTypes.string
}

export default NewsDetail
