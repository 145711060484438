import { ACTIONS } from '../constants/Layout'

export function toggleLayoutFlag (flag) {
  return {
    type: ACTIONS.TOGGLE_LAYOUT_FLAG,
    flag: flag
  }
}

export default {
  toggleLayoutFlag
}
