import React from 'react'

export default props => {
  const {
    highlighted,
    normal
  } = props

  if (highlighted) {
    return (
      <span dangerouslySetInnerHTML={{ __html: highlighted }}/>
    )
  }

  return normal
}
