import { getInitialState as getNewsInit } from '../reducers/news'
import { getInitialState as getQueryFilterInit } from '../reducers/queryFilter'
import { AGENCIES } from '../constants/News'
import { FILTERS_CTK, FILTERS_REUTERS } from '../constants/QueryFilter'

/**
 * Config for persistState function (customized logic for (de)serialization of state)
 *
 * @type {{serialize: PersistConfig.serialize, deserialize: PersistConfig.deserialize, merge: PersistConfig.merge}}
 */
export const PersistConfig = {
  serialize: (subset) => {
    const queryFilter = subset.queryFilter.toJS()
    for (let agency in queryFilter) {
      if (queryFilter.hasOwnProperty(agency)) {
        queryFilter[agency] = queryFilter[agency]['ALL_FILTERS'].filter((o) => {
          return (o.checked === 1)
        })
      }
    }
    const news = Object.assign({}, getNewsInit(), {
      flasher: subset.news.flasher,
      flasherSound: subset.news.flasherSound
    })
    const toSave = Object.assign({}, {
      layout: subset.layout,
      queryFilter: queryFilter,
      news: news
    })
    return JSON.stringify(toSave, (key, value) => {
      if (key === 'parent') {
        return undefined
      } else {
        return value
      }
    })
  },
  deserialize: (load) => {
    if (typeof load === 'string') {
      const loaded = JSON.parse(load)
      let {
        queryFilter
      } = loaded
      if (typeof queryFilter === 'object') {
        for (let agency in queryFilter) {
          if (queryFilter.hasOwnProperty(agency) && Array.isArray(queryFilter[agency])) {
            for (let i in queryFilter[agency]) {
              let filters
              if (agency === AGENCIES.CTK) {
                filters = FILTERS_CTK
              } else if (agency === AGENCIES.REUTERS) {
                filters = FILTERS_REUTERS
              }

              for (let j in filters) {
                if (filters[j].name === queryFilter[agency][i].name &&
                  filters[j].label === queryFilter[agency][i].label &&
                  filters[j].value === queryFilter[agency][i].value
                ) {
                  filters[j].checked = true
                }
              }
            }
          }
        }
      }
      queryFilter = getQueryFilterInit({
        [AGENCIES.CTK]: FILTERS_CTK,
        [AGENCIES.REUTERS]: FILTERS_REUTERS
      })
      return Object.assign(loaded, {
        queryFilter: queryFilter
      })
    } else {
      return load
    }
  },
  merge: (initState, load) => {
    return Object.assign({}, initState, load)
  }
}
