import React, { Component } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import Highlightable from '../Highlightable'
import { EXPORT_BTN_SETTINGS } from '../../constants/News'

export default class ItemReutersPhotos extends Component {
  render () {
    const {
      loadDetail,
      news: {
        exports,
        resized_image: resizedImage,
        title,
        title_highlighted: titleHighlighted,
        unique_id: uniqueId,
        updated
      }
    } = this.props

    const date = moment(updated)

    let exportName
    if (exports.length > 0) {
      exportName = exports[0]
    }

    const httpsResizedImage = resizedImage.replace('http://', 'https://')

    return (
      <div className='reuters-photo-holder' onClick={() => loadDetail(uniqueId)}>
        {exportName
          ? <span
            className='reuters-photo-export-indicator'
            style={{ borderColor: EXPORT_BTN_SETTINGS[exportName] ? EXPORT_BTN_SETTINGS[exportName].color : '' }}
          />
          : ''
        }
        <img className='reuters-image' src={httpsResizedImage} alt={title}/>
        <div className='reuters-date'>
          { date.format('DD.MM. HH:mm:ss') }&nbsp;({ date.locale('cs').fromNow() })
        </div>
        <div>
          <Highlightable highlighted={titleHighlighted} normal={title}/>
        </div>
      </div>
    )
  }
}

ItemReutersPhotos.propTypes = {
  loadDetail: PropTypes.func,
  news: PropTypes.object
}
