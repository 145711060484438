import {
  REGIONS_CTK,
  DESKS_CTK,
  PRIORITIES_CTK,
  NEWS_TYPES_LABELS_CTK,
  SPECIAL_TYPES_REUTERS,
  PRIORITIES_REUTERS,
  REUTERS_PHOTOS_PRIORITIES,
  REUTERS_PHOTOS_SECTIONS,
  CATEGORIES_CTK
} from './News'

export const PLACEMENT = {
  TOP: 'PLACEMENT_TOP',
  LEFT: 'PLACEMENT_LEFT',
  BOTTOM: 'Group_Bottom'
}

export const GROUPS_CTK = {
  SECTIONS: {
    'title': 'Rubriky',
    'open': true,
    'type': 'checkboxes'
  },
  REGIONS: {
    'title': 'Regiony',
    'open': true,
    'type': 'checkboxes'
  },
  TYPES: {
    'title': 'Typy zpráv',
    'open': true,
    'type': 'checkboxes'
  },
  CATEGORIES: {
    'title': 'Kategorie',
    'open': false,
    'type': 'checkboxes'
  },
  PRIORITIES: {
    'title': 'Priorita',
    'open': true,
    'type': 'checkboxes'
  },
  DATE_RANGE: {
    'title': 'Datum',
    'open': true,
    'type': 'dateRange'
  }
}

export const GROUPS_REUTERS = {
  TYPES: {
    'title': 'Typy zpráv',
    'open': true,
    'type': 'checkboxes'
  },
  PRIORITIES: {
    'title': 'Priorita',
    'open': true,
    'type': 'checkboxes'
  },
  DATE_RANGE: {
    'title': 'Datum',
    'open': true,
    'type': 'dateRange'
  }
}

export const GROUPS_REUTERS_PHOTOS = {
  SECTIONS: {
    'title': 'Sekce',
    'open': true,
    'type': 'checkboxes'
  },
  PRIORITIES: {
    'title': 'Priorita',
    'open': true,
    'type': 'checkboxes'
  },
  DATE_RANGE: {
    'title': 'Datum',
    'open': true,
    'type': 'dateRange'
  }
}

export const ACTIONS = {
  SET_VALUE: 'SET_VALUE',
  SET_CHECKED: 'SET_CHECKED',
  EMPTY_VALUE: 'EMPTY_VALUE',
  SELECT_PAGE: 'SELECT_PAGE'
}

function generateCheckboxesFromCheckboxGroup (data, common) {
  let filters = []
  Object.keys(data).forEach(key => {
    switch (typeof data[key]) {
      case 'object' :
        const filter = Object.assign({}, common, {
          label: key,
          'checked': false,
          'type': 'CheckboxGroup'
        })
        filters.push(filter)
        const children = generateCheckboxesFromCheckboxGroup(data[key], Object.assign({}, common, { parent: filter }))
        filter.children = children
        filters = filters.concat(children)
        break
      default:
        filters.push(Object.assign({}, common, {
          label: data[key],
          value: key,
          checked: false,
          type: 'Checkbox'
        }))
    }
  })
  return filters
}

const FILTERS_CTK = [
  {
    'label': 'Vyhledávání',
    'name': 'text',
    'placeholder': 'search...',
    'placement': PLACEMENT.TOP,
    'value': '',
    'type': 'Text'
  },
  {
    'label': 'Řazení',
    'name': 'sort_date',
    'options': [
      {
        'label': 'Od nejnovějších',
        'value': 'desc'
      },
      {
        'label': 'Od nejstarších',
        'value': 'asc'
      }
    ],
    'placement': PLACEMENT.TOP,
    'type': 'Select'
  },
  {
    'format': 'YYYY-MM-DD 00:00:00',
    'group': GROUPS_CTK.DATE_RANGE,
    'label': 'Datum od',
    'name': 'date_from',
    'placement': PLACEMENT.LEFT,
    'type': 'datepicker'
  },
  {
    'format': 'YYYY-MM-DD 23:59:59',
    'group': GROUPS_CTK.DATE_RANGE,
    'label': 'Datum do',
    'name': 'date_to',
    'placement': PLACEMENT.LEFT,
    'type': 'datepicker'
  }
]

const FILTERS_REUTERS = [
  {
    'label': 'Vyhledávání',
    'name': 'text',
    'placeholder': 'search...',
    'placement': PLACEMENT.TOP,
    'value': '',
    'type': 'Text'
  },
  {
    'label': 'Řazení',
    'name': 'sort_date',
    'options': [
      {
        'label': 'Od nejnovějších',
        'value': 'desc'
      },
      {
        'label': 'Od nejstarších',
        'value': 'asc'
      }
    ],
    'placement': PLACEMENT.TOP,
    'type': 'Select'
  },
  {
    'format': 'YYYY-MM-DD 00:00:00',
    'group': GROUPS_REUTERS.DATE_RANGE,
    'label': 'Datum od',
    'name': 'date_from',
    'placement': PLACEMENT.LEFT,
    'type': 'datepicker'
  },
  {
    'format': 'YYYY-MM-DD 23:59:59',
    'group': GROUPS_REUTERS.DATE_RANGE,
    'label': 'Datum do',
    'name': 'date_to',
    'placement': PLACEMENT.LEFT,
    'type': 'datepicker'
  }
]

const FILTERS_REUTERS_PHOTOS = [
  {
    'label': 'Vyhledávání',
    'name': 'description',
    'placeholder': 'search...',
    'placement': PLACEMENT.TOP,
    'value': '',
    'type': 'Text'
  },
  {
    'label': 'Řazení',
    'name': 'sort_date',
    'options': [
      {
        'label': 'Od nejnovějších',
        'value': 'desc'
      },
      {
        'label': 'Od nejstarších',
        'value': 'asc'
      }
    ],
    'placement': PLACEMENT.TOP,
    'type': 'Select'
  },
  {
    'format': 'YYYY-MM-DD 00:00:00',
    'group': GROUPS_REUTERS_PHOTOS.DATE_RANGE,
    'label': 'Datum od',
    'name': 'date_from',
    'placement': PLACEMENT.LEFT,
    'type': 'datepicker'
  },
  {
    'format': 'YYYY-MM-DD 23:59:59',
    'group': GROUPS_REUTERS_PHOTOS.DATE_RANGE,
    'label': 'Datum do',
    'name': 'date_to',
    'placement': PLACEMENT.LEFT,
    'type': 'datepicker'
  }
]

FILTERS_CTK.push.apply(FILTERS_CTK, generateCheckboxesFromCheckboxGroup(DESKS_CTK, {
  'group': GROUPS_CTK.SECTIONS,
  'name': 'desks',
  'placement': PLACEMENT.LEFT
}))
FILTERS_CTK.push.apply(FILTERS_CTK, generateCheckboxesFromCheckboxGroup(NEWS_TYPES_LABELS_CTK, {
  'group': GROUPS_CTK.TYPES,
  'name': 'keywords',
  'placement': PLACEMENT.LEFT
}))
FILTERS_CTK.push.apply(FILTERS_CTK, generateCheckboxesFromCheckboxGroup(REGIONS_CTK, {
  'group': GROUPS_CTK.REGIONS,
  'name': 'regions',
  'placement': PLACEMENT.LEFT
}))
FILTERS_CTK.push.apply(FILTERS_CTK, generateCheckboxesFromCheckboxGroup(CATEGORIES_CTK, {
  'group': GROUPS_CTK.CATEGORIES,
  'name': 'categories',
  'placement': PLACEMENT.LEFT
}))
FILTERS_CTK.push.apply(FILTERS_CTK, generateCheckboxesFromCheckboxGroup(PRIORITIES_CTK, {
  'group': GROUPS_CTK.PRIORITIES,
  'name': 'priorities',
  'placement': PLACEMENT.LEFT
}))
FILTERS_REUTERS.push.apply(FILTERS_REUTERS, generateCheckboxesFromCheckboxGroup(PRIORITIES_REUTERS, {
  'group': GROUPS_REUTERS.PRIORITIES,
  'name': 'priorities',
  'placement': PLACEMENT.LEFT
}))
FILTERS_REUTERS.push.apply(FILTERS_REUTERS, generateCheckboxesFromCheckboxGroup(SPECIAL_TYPES_REUTERS, {
  'group': GROUPS_REUTERS.TYPES,
  'name': 'special_type',
  'placement': PLACEMENT.LEFT
}))
FILTERS_REUTERS_PHOTOS.push.apply(FILTERS_REUTERS_PHOTOS, generateCheckboxesFromCheckboxGroup(REUTERS_PHOTOS_PRIORITIES, {
  'group': GROUPS_REUTERS_PHOTOS.PRIORITIES,
  'name': 'priorities',
  'placement': PLACEMENT.LEFT
}))
FILTERS_REUTERS_PHOTOS.push.apply(FILTERS_REUTERS_PHOTOS, generateCheckboxesFromCheckboxGroup(REUTERS_PHOTOS_SECTIONS, {
  'group': GROUPS_REUTERS_PHOTOS.SECTIONS,
  'name': 'section',
  'placement': PLACEMENT.LEFT
}))

export {
  FILTERS_CTK,
  FILTERS_REUTERS,
  FILTERS_REUTERS_PHOTOS
}
