import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Flasher from './Flasher'
import { createFormItem } from './factory'

class FormBox extends Component {
  render () {
    const {
      filters,
      agency
    } = this.props

    return (
      <div>
        <div className='col-sm-2 logo-box'>
          <img
            className='logo-box-img'
            src={cia_config.newsApi + '/static/images/' + agency.toLowerCase() + '_logo.png'}
            alt=''
          />
        </div>
        {
          filters.map((filter, key) => createFormItem(filter.type, { filter, key }))
        }
        <Flasher/>
      </div>
    )
  }
}

FormBox.propTypes = {
  filters: PropTypes.array
}

export default FormBox
