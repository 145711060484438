import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import CheckboxSection from './CheckboxSection'
import Agency from './Agency'
import DateRange from './DateRange'
import { LAYOUT_FLAGS } from '../../constants/Layout'

export default class LeftMenu extends Component {
  renderDateRangeGroup (group, filters, filtersGroups) {
    return (
      <Fragment key={group}>
        <div key={`${group}-title`} className='col-sm-12 left-form-top'>
          { filtersGroups[group].title }:
        </div>
        <div key={`${group}-filter`} className='col-md-12 left-form-content'>
          <DateRange filter={filters}/>
        </div>
      </Fragment>
    )
  }

  render () {
    const {
      filters,
      filtersGroups,
      layout
    } = this.props

    if (!(layout.mask & LAYOUT_FLAGS.LEFT_PANEL)) {
      return null
    }

    return (
      <div className='col-sm-3 col-lg-2 main-content-left-block'>
        <div className='col-sm-12 left-form-top'>
          Agentury
        </div>
        <Agency/>
        {
          Object.keys(filtersGroups).map(key => {
            const groupItems = filters.filter(filter => filter.group === filtersGroups[key])

            switch (filtersGroups[key].type) {
              case 'checkboxes' :
                if (groupItems.length > 0) {
                  return <CheckboxSection key={key} group={key} groupItems={groupItems} filterGroup={filtersGroups[key]}/>
                }
                break
              case 'dateRange': {
                if (groupItems.length === 2) {
                  return this.renderDateRangeGroup(key, groupItems, filtersGroups)
                }
              }
            }
          })
        }
      </div>
    )
  }
}

LeftMenu.propTypes = {
  filters: PropTypes.array,
  layout: PropTypes.object
}
