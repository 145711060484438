export const ACTIONS = {
  TOGGLE_LAYOUT_FLAG: 'TOGGLE_LAYOUT_FLAG'
}

export const LAYOUT_FLAGS = {
  DETAIL_POSITION: 1, // switch position right/bottom
  LEFT_PANEL: 2, // open/close left panel
  DETAIL: 4 // open/close detail window
}

export const FORM_SECTION_MAX_ITEMS = 10
