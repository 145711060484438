import { ACTIONS, TYPES } from '../constants/Modal'

export function getInitialState () {
  return {
    show: false,
    title: 'Export comment',
    type: TYPES.EXPORT_COMMENT,
    config: {}
  }
}

export default function layout (state = getInitialState(), action) {
  switch (action.type) {
    case ACTIONS.OPEN:
      return Object.assign({}, state, action.config, {
        show: true
      })
    case ACTIONS.CLOSE:
      return Object.assign({}, state, {
        show: false
      })
    default:
      return state
  }
}
