import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { setValue } from '../../actions/queryFilter'
import DateRangePicker from 'react-daterange-picker'
import Moment from 'moment'
import { extendMoment } from 'moment-range'
import '../../css/lib/datepicker.css'
import 'moment/locale/cs'

const moment = extendMoment(Moment)
moment.locale('cs')

class DateRange extends Component {
  handleChange = event => {
    const {
      filter,
      dispatch
    } = this.props

    dispatch(setValue(filter[0], event.start, false))
    dispatch(setValue(filter[1], event.end))
  }

  handleClick = () => {
    const {
      filter,
      dispatch
    } = this.props

    dispatch(setValue(filter[0], null, false))
    dispatch(setValue(filter[1], null))
  }

  render () {
    const {
      filter
    } = this.props

    const rangeStart = filter[0].value || moment()
    const rangeEnd = filter[1].value || moment()
    const value = moment.range(rangeStart, rangeEnd)

    return (
      <div>
        <DateRangePicker
          firstOfWeek={1}
          numberOfCalendars={1}
          selectionType='range'
          singleDateRange={true}
          maximumDate={new Date()}
          value={value}
          onSelect={this.handleChange}
        />
        {
          (filter[0].value || filter[1].value) && (
            <div onClick={this.handleClick} className='reset-date'>
              Zrušit výběr
            </div>
          )
        }
      </div>
    )
  }
}

DateRange.propTypes = {
  dispatch: PropTypes.func.isRequired,
  filter: PropTypes.array.isRequired
}

export default connect()(DateRange)
