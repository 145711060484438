import React from 'react'
import SearchText from './SearchText'
import SortSelect from './SortSelect'

const types = {
  'Text': SearchText,
  'Select': SortSelect
}

export function createFormItem (name = 'text', props = {}) {
  return React.createElement(types[name], props)
}

export default {
  createFormItem
}
