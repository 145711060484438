/**
 * Function to toggle 0/1 in binary string (egg number)
 * Example:
 *   var flag1 = 1
 *   var flag2 = 2
 *   var flag3 = 4
 *   var flag4 = 8
 *   var mask = flag1 | flag4 // result is 9
 *   toggleFlagInMask(mask, flag2) // mask was 9, returns 11
 *   toggleFlagInMask(mask, flag1) // mask was 9, returns 8
 *
 * @param mask Number to alter
 * @param flag Modifier
 * @returns {*}
 */
export function toggleFlagInMask (mask, flag) {
  if (mask & flag) {
    mask = mask - flag
  } else {
    mask = mask + flag
  }
  return mask
}
