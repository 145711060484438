import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { setValue } from '../../actions/queryFilter'

class SearchText extends Component {
  handleChange = () => {
    const {
      filter,
      dispatch
    } = this.props

    if (this._timeout) {
      clearTimeout(this._timeout)
    }

    this._timeout = setTimeout(() => {
      dispatch(setValue(filter, this.refs[filter.name].value))
    }, 250)
  }

  render () {
    const {
      filter
    } = this.props

    return (
      <div className='col-sm-5 col-md-6 search-text-box'>
        <div className='col-sm-12 search-text-title'>
          { filter.label }:
        </div>
        <div className='col-sm-12 search-text-input-box'>
          <input
            type='text'
            defaultValue={filter.value}
            name={filter.name}
            ref={filter.name}
            onChange={this.handleChange}
            className='search-text form-control input-sm'
          />
        </div>
      </div>
    )
  }
}

SearchText.propTypes = {
  filter: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired
}

export default connect()(SearchText)
