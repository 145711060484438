import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { setValue, setChecked } from '../../actions/queryFilter'

class Checkbox extends Component {
  handleChange = event => {
    const {
      filter,
      dispatch
    } = this.props

    const checked = event.target.checked || event.target.value === 'on'
    dispatch(setChecked([filter], checked))
    dispatch(setValue(filter, filter.value))
  }

  render () {
    const {
      filter
    } = this.props

    return (
      <div>
        <div className={`col-sm-8 col-md-9 form-label level${filter.parent ? 2 : 1}`}>
          { filter.label }
        </div>
        <div className='col-sm-4 col-md-3 chbholder'>
          <input
            type='checkbox'
            name={filter.name}
            value={filter.value}
            onChange={this.handleChange}
            checked={filter.checked}
          />
        </div>
      </div>
    )
  }
}

Checkbox.propTypes = {
  filter: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired
}

export default connect()(Checkbox)
