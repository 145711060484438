import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { AGENCIES, EXPORT_BTN_SETTINGS } from '../../constants/News'
import ItemCtk from './ItemCtk'
import ItemReuters from './ItemReuters'
import ItemReutersPhotos from './ItemReutersPhotos'
import InfiniteScrollify from '../InfiniteScrollify'
import { LAYOUT_FLAGS } from '../../constants/Layout'

class List extends Component {
  getExports (news) {
    if (Array.isArray(news.exports)) {
      return news.exports.map((name, index) => {
        return (
          <span
            className='news-icon-span'
            style={{ background: EXPORT_BTN_SETTINGS[name] ? EXPORT_BTN_SETTINGS[name].color : '' }}
            key={index}
          >
            { name }
          </span>
        )
      })
    }
  }

  render () {
    const {
      agency,
      entities,
      layout,
      list,
      loadDetail,
      onScroll
    } = this.props

    let ItemComponent

    switch (agency) {
      case AGENCIES.REUTERS_PHOTOS:
        ItemComponent = ItemReutersPhotos
        break
      case AGENCIES.REUTERS:
        ItemComponent = ItemReuters
        break
      case AGENCIES.CTK:
      default:
        ItemComponent = ItemCtk
    }

    return (
      <div
        className={`
          table-striped
          col-sm-12
          news-body
          ${(layout.mask & LAYOUT_FLAGS.DETAIL_POSITION) ? ' news-wide' : ''}
          ${agency === AGENCIES.REUTERS_PHOTOS ? ' flex-list' : ''}
        `}
        onScroll={onScroll}
      >
        {
          list.map(id => {
            const news = entities[id]

            if (typeof news === 'undefined') {
              return null
            }

            return (
              <ItemComponent
                exports={this.getExports(news)}
                key={`${news.unique_id}_${news.exports.length || 0}`}
                layout={layout}
                loadDetail={loadDetail}
                news={news}
              />
            )
          })
        }
      </div>
    )
  }
}

List.propTypes = {
  loadDetail: PropTypes.func.isRequired,
  list: PropTypes.array.isRequired,
  onScroll: PropTypes.func.isRequired,
  layout: PropTypes.object
}

export default InfiniteScrollify(List)
