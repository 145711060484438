import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { setValue } from '../../actions/queryFilter'

class SortSelect extends Component {
  handleChange = event => {
    const {
      filter,
      dispatch
    } = this.props

    dispatch(setValue(filter, event.target.value))
  }

  render () {
    const {
      filter
    } = this.props

    return (
      <div className='col-sm-2 search-text-box'>
        <div className='col-sm-12 search-text-title'>
          { filter.label }:
        </div>
        <div className='col-sm-12'>
          <select
            className='search-text-select form-control input-sm'
            name={filter.name}
            value={filter.value}
            onChange={this.handleChange}
          >
            {
              filter.options.map((option, index) => {
                return (
                  <option key={index} value={option.value}>
                    {option.label}
                  </option>
                )
              })
            }
          </select>
        </div>
      </div>
    )
  }
}

SortSelect.propTypes = {
  filter: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired
}

export default connect()(SortSelect)
