import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import Checkbox from './Checkbox'
import CheckboxGroup from './CheckboxGroup'
import { FORM_SECTION_MAX_ITEMS } from './../../constants/Layout'

export default class CheckboxSection extends Component {
  constructor (props) {
    super(props)
    this.state = { isOpen: false }
  }

  componentDidMount () {
    const {
      filterGroup
    } = this.props

    this.setState({ isOpen: filterGroup.open })
  }

  handleOpenClose = () => {
    this.setState({ isOpen: !this.state.isOpen })
  }

  render () {
    const {
      group,
      groupItems,
      filterGroup
    } = this.props

    const {
      isOpen
    } = this.state

    return (
      <Fragment key={group}>
        <div className='col-sm-12 left-form-top'>
          { filterGroup.title }:
        </div>
        <div className='col-md-12 left-form-content'>
          {
            groupItems.map((filter, key) => {
              if (filter.type === 'Checkbox' && (isOpen === true || FORM_SECTION_MAX_ITEMS > key)) {
                return (
                  <Checkbox
                    filter={filter}
                    key={key + filter.value + filter.checked + (filter.parent ? filter.parent.checked : '')}
                  />
                )
              }
              if (filter.type === 'CheckboxGroup') {
                const checkedAll = filter.children.every(child => child.checked)
                return (
                  <CheckboxGroup filter={filter} checked={checkedAll} key={key + filter.label + checkedAll}/>
                )
              }
            })
          }
          { !isOpen ? (
            <div className="glyphicon left-form-open glyphicon-menu-down" title="Otevřít/zavřít menu" onClick={this.handleOpenClose}/>
          ) : (
            <div/>
          )
          }
        </div>
      </Fragment>
    )
  }
}

CheckboxSection.propTypes = {
  group: PropTypes.string,
  groupItems: PropTypes.array,
  filterGroup: PropTypes.object
}
