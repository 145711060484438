import React, { Component } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { LAYOUT_FLAGS } from '../../constants/Layout'
import Highlightable from '../Highlightable'

export default class ItemReuters extends Component {
  render () {
    const {
      exports,
      layout,
      loadDetail,
      news
    } = this.props

    const date = moment(news.updated)

    let newsStyles = 'col-sm-12 table-row news-row'

    if (news.special_type && news.special_type === 'BULLETIN') {
      newsStyles += ' flash'
    }
    if (news.special_type && news.special_type === 'URGENT') {
      newsStyles += ' urgent'
    }
    if (news.title && news.title.indexOf('UPDATE') === 0) {
      newsStyles += ' verze'
    }
    if (news.special_type && news.special_type === 'BRIEF') {
      newsStyles += ' brief'
    }

    return (
      <div className={newsStyles} onClick={() => loadDetail(news.unique_id)}>
        <div className='col-sm-10 table-cell news-cell'>
          <div className={`datetime ${(layout.mask & LAYOUT_FLAGS.DETAIL_POSITION) ? 'time-narrow' : 'time-wide'}`}>
            { moment(date).format('DD.MM. HH:mm:ss') }&nbsp;({ moment(date).locale('cs').fromNow() })
          </div>
          <div>
            <Highlightable highlighted={news.title_highlighted} normal={news.title}/>
          </div>
        </div>
        <div className='news-icon-box col-sm-2 table-cell news-cell'>
          {
            (news.special_type && news.special_type === 'BULLETIN') && (
              <span className='fl news-icon-span' title='fleš'>
                fleš
              </span>
            )
          }
          {
            (news.special_type && news.special_type === 'URGENT') && (
              <span className='ur news-icon-span' title='urgent'>
                urgent
              </span>
            )
          }
          {
            (news.special_type && news.special_type === 'BRIEF') && (
              <span className='br news-icon-span' title='brief'>
                brief
              </span>
            )
          }
          {
            (news.title && news.title.indexOf('UPDATE') === 0 && news.title.indexOf('-') > 0) && (
              <span
                className='vz news-icon-span'
                title={news.title.substring(0, news.title.indexOf('-')).toLowerCase()}
              >
                { news.title.substring(0, news.title.indexOf('-')).toLowerCase() }
              </span>
            )
          }
          { exports }
        </div>
      </div>
    )
  }
}

ItemReuters.propTypes = {
  news: PropTypes.object,
  loadDetail: PropTypes.func,
  layout: PropTypes.object
}
