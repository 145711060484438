import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import moment from 'moment'
import { LAYOUT_FLAGS } from '../../constants/Layout'
import Modal from '../Modal/index'
import Export from './Export'
import ExportLog from './ExportLog'
import Highlightable from '../Highlightable'

class ReutersDetail extends Component {
  render () {
    const {
      detail,
      detailAgency,
      exports,
      layout,
      modal,
      news
    } = this.props

    const exportColors = {}
    if (exports.data[detailAgency]) {
      exports.data[detailAgency].forEach(exportData => {
        exportColors[exportData.name] = exportData.color
      })
    }
    const date = moment(detail.updated)
    const isFetchingDetail = news.isFetchingDetail
    let containerClasses = 'main-content-right-block'
    switch (layout.mask) {
      case LAYOUT_FLAGS.LEFT_PANEL | LAYOUT_FLAGS.DETAIL | LAYOUT_FLAGS.DETAIL_POSITION:
        containerClasses += ' col-sm-9 col-lg-10 right-wide'
        break
      case LAYOUT_FLAGS.DETAIL | LAYOUT_FLAGS.DETAIL_POSITION:
        containerClasses += ' col-sm-12 right-wide'
        break
    }

    return (
      <div className={containerClasses}>
        <div className={isFetchingDetail ? 'detail-loader' : 'loaded'}/>
        <div className='col-md-12 modal-container newstext'>
          <div className='export-buttons'>
            {
              (exports.data[detailAgency] && exports.data[detailAgency].length)
                ? exports.data[detailAgency].map(exportData =>
                  <Export exportData={exportData} detail={detail} key={exportData.name} modal={exportData.modal}/>
                )
                : ''
            }
            <Export exportData={{ name: 'výchozí', title: 'Převzít' }} detail={detail} key="modal-test" modal={true}/>
          </div>
          <div className='maindate'>
            { moment(date).format('DD.MM.YYYY HH:mm:ss') }
          </div>
          {
            (detail.special_type && detail.special_type === 'BULLETIN') && (
              <div className='detail-flash'>
                Fleš
              </div>
            )
          }
          {
            (detail.special_type && detail.special_type === 'URGENT') && (
              <div className='detail-urgent'>
                Urgent
              </div>
            )
          }
          {
            (detail.special_type && detail.special_type === 'BRIEF') && (
              <div className='detail-brief'>
                Brief
              </div>
            )
          }
          {
            (detail.title && detail.title.indexOf('UPDATE') === 0 && detail.title.indexOf('-') > 0) && (
              <div className='detail-verze'>
                { detail.title.substring(0, detail.title.indexOf('-')).toLowerCase() }
              </div>
            )
          }
          <h1>
            <Highlightable highlighted={detail.title_highlighted} normal={detail.title}/>
          </h1>
          <div dangerouslySetInnerHTML={{ __html: detail.text_highlighted ? detail.text_highlighted : detail.text }}/>
          <table className='table table-striped detailinfo'>
            <thead>
              <tr>
                <th colSpan={5}>
                  Info
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className='col-md-3'>
                  <strong>
                    Priorita:
                  </strong>
                </td>
                <td className='col-md-2'>
                  { detail.priority }
                </td>
              </tr>
              <tr>
                <td className='col-md-3'>
                  <strong>
                    Guid:
                  </strong>
                </td>
                <td className='col-md-2'>
                  { detail.guid }
                </td>
              </tr>
              <tr>
                <td className='col-md-3'>
                  <strong>
                    Revize:
                  </strong>
                </td>
                <td className='col-md-2'>
                  { detail.revision_id }
                </td>
              </tr>
              <tr>
                <td className='col-md-3'>
                  <strong>
                    Slugline:
                  </strong>
                </td>
                <td className='col-md-2'>
                  { detail.slugline }
                </td>
              </tr>
            </tbody>
          </table>
          {
            (detail.exports && detail.exports.length > 0) &&
            <ExportLog dispatch={this.props.dispatch} exports={detail.exports} news={detail}/>
          }
          <Modal dispatch={this.props.dispatch} {...modal} />
        </div>
      </div>
    )
  }
}

ReutersDetail.propTypes = {
  news: PropTypes.object,
  layout: PropTypes.object,
  modal: PropTypes.object,
  exports: PropTypes.object
}

function mapStateToProps (state) {
  const {
    news,
    layout,
    modal,
    exports
  } = state

  return {
    news,
    layout,
    modal,
    exports
  }
}

export default connect(mapStateToProps)(ReutersDetail)
