import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { changeAgency } from '../../actions/news'
import { AGENCIES_NAMES } from '../../constants/News'

class Agency extends Component {
  handleChange = event => {
    const {
      dispatch
    } = this.props

    dispatch(changeAgency(event.target.value))
  }

  render () {
    const {
      agency
    } = this.props

    return (
      <div className='col-md-12 left-form-content'>
        {
          Object.keys(AGENCIES_NAMES).map(key => {
            return (
              <div key={key}>
                <div className='col-sm-8 col-md-9 form-label'>
                  { AGENCIES_NAMES[key] }
                </div>
                <div className='col-sm-4 col-md-3 chbholder'>
                  <input
                    type='radio'
                    name='agency'
                    value={key}
                    checked={key === agency}
                    onChange={this.handleChange}
                  />
                </div>
              </div>
            )
          })
        }
      </div>
    )
  }
}

Agency.propTypes = {
  agency: PropTypes.string.isRequired
}

function mapStateToProps (state) {
  const {
    agency
  } = state.news

  return {
    agency
  }
}

export default connect(mapStateToProps)(Agency)
