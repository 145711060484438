import React, { Component } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'

class NewsDetailRelated extends Component {
  handleClick = (event, news) => {
    event.preventDefault()
    const {
      loadDetail
    } = this.props
    loadDetail(news.unique_id)
  }

  render () {
    const {
      related
    } = this.props

    return (
      <table className='table table-striped detailinfo'>
        <thead>
          <tr>
            <th>
              Související zprávy
            </th>
          </tr>
        </thead>
        <tbody>
          {
            (related || []).map(news => (
              <tr key={news.unique_id}>
                <td>
                  <div>
                    <div className='datetime-detail'>
                      { moment(news.updated).format('DD.MM.YYYY HH:mm:ss') }
                    </div>
                    <a href='#' onClick={event => this.handleClick(event, news)}>
                      { news.title }
                    </a>
                  </div>
                </td>
              </tr>
            ))
          }
        </tbody>
      </table>
    )
  }
}

NewsDetailRelated.propTypes = {
  loadDetail: PropTypes.func,
  related: PropTypes.array
}

export default NewsDetailRelated
