import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { MERGE_STYLES } from '../../constants/News'
import { PLACEMENT } from '../../constants/QueryFilter'
import { requestNewsDetail, requestNewsListIfNeeded, requestNewsRelatedDetail } from '../../actions'
import FormBox from '../FormBox'
import LeftMenu from '../LeftMenu'
import NewsList from '../NewsList'
import NewsDetail from '../NewsDetail'
import Flashing from './Flashing'

class Agency extends Component {
  componentWillReceiveProps (nextProps) {
    const {
      agency,
      isExporting
    } = this.props

    clearTimeout(this.timeout)

    if (!nextProps.isFetchingList) {
      this.timeout = setTimeout(() => this.autoLoadNewsList(), 30000)
    }

    if (nextProps.agency !== agency) {
      this.loadNewsList()
    }

    if (isExporting && !nextProps.isExporting && nextProps.news.lastExportedResult !== 'ok') {
      window.alert('Zprávu se nepodařilo exportovat: ' + nextProps.news.lastExportedResult)
    }
  }

  componentWillUnmount () {
    clearTimeout(this.timeout)
  }

  componentDidMount () {
    this.loadNewsList()
  }

  autoLoadNewsList = () => {
    const {
      dispatch
    } = this.props
    dispatch(requestNewsListIfNeeded(MERGE_STYLES.PREPEND))
  }

  loadNewsList = () => {
    const {
      dispatch
    } = this.props
    dispatch(requestNewsListIfNeeded(MERGE_STYLES.REPLACE))
  }

  loadDetail = id => {
    const {
      dispatch
    } = this.props
    dispatch(requestNewsDetail(id))
  }

  loadRelatedDetail = id => {
    const {
      dispatch
    } = this.props
    dispatch(requestNewsRelatedDetail(id))
  }

  render () {
    const {
      loadDetail,
      loadNewsList,
      loadRelatedDetail,
      props,
      props: {
        agency,
        entities,
        items,
        layout,
        queryFilter,
        related
      }
    } = this

    return (
      <div className='cia-app'>
        <div className='head'>
          <div className='container col-md-12'>
            <FormBox filters={queryFilter[PLACEMENT.TOP]} agency={agency}/>
          </div>
        </div>
        <div className='container row main-content'>
          <LeftMenu filters={queryFilter[PLACEMENT.LEFT]} layout={layout} filtersGroups={queryFilter.Groups}/>
          <NewsList
            agency={agency}
            entities={entities}
            list={items}
            layout={layout}
            loadDetail={loadDetail}
            reload={loadNewsList}
          />
          <NewsDetail {...props} loadRelatedDetail={related ? loadRelatedDetail : null}/>
        </div>
        <div className='col-md-12 footer'/>
      </div>
    )
  }
}

Agency.propTypes = {
  agency: PropTypes.string.isRequired,
  dispatch: PropTypes.func.isRequired,
  entities: PropTypes.object.isRequired,
  items: PropTypes.array.isRequired,
  queryFilter: PropTypes.object.isRequired,
  related: PropTypes.bool
}

function mapStateToProps (state) {
  const {
    isFetchingList: isFetching,
    layout,
    news,
    news: {
      detail,
      detailAgency,
      isExporting
    }
  } = state

  return {
    detail,
    detailAgency,
    isFetching,
    isExporting,
    layout,
    news
  }
}

export default connect(mapStateToProps)(Flashing(Agency))
