import React, { Component } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { setFlashesSeen, setFlashing } from '../../actions'
import { SOUND_ALERT } from '../../constants/News'
import { getApiUrl } from '../../utils/News'

export default function (InnerComponent) {
  class Flashing extends Component {
    componentWillReceiveProps (nextProps) {
      const {
        flashes,
        flasher,
        isFlashing,
        flasherSound
      } = nextProps.news
      const {
        dispatch,
        entities,
        agency
      } = this.props

      if (this.isPageHidden() && !isFlashing && flasher && flashes.length > 0) {
        dispatch(setFlashing(true))
      } else if (!this.isPageHidden() && flashes.length > 0) {
        dispatch(setFlashesSeen(flashes))
      }
      if (isFlashing && flasher) {
        if (flasherSound) {
          const alert = new Audio(SOUND_ALERT)
          alert.play()
        }
        const msg = (flashes.length === 1) ? 'Byly přijaty nové Fleš zprávy' : 'Byla přijata nová Fleš zpráva'
        if (!('Notification' in window)) {
          window.alert(msg)
        } else if (Notification.permission === 'granted') {
          for (let i = 0; i < flashes.length; i++) {
            const title = (entities[flashes[i]]) ? entities[flashes[i]].title : 'Nová fleš zpráva'
            const body = (entities[flashes[i]]) ? moment(entities[flashes[i]].updated).fromNow() : null
            const options = {
              body: body,
              icon: getApiUrl() + '/static/images/' + agency.toLowerCase() + '_logo.png'
            }
            const notification = new Notification(title, options)
            notification.onclick = () => {
              window.focus()
              notification.close()
            }
          }
        } else if (Notification.permission !== 'denied') {
          Notification.requestPermission()
        }
        dispatch(setFlashesSeen(flashes))
      }
    }

    render () {
      return <InnerComponent {...this.props} />
    }

    isPageHidden () {
      return (
        (typeof document.hidden !== 'undefined' && document.hidden) ||
        (typeof document.mozHidden !== 'undefined' && document.mozHidden) ||
        (typeof document.msHidden !== 'undefined' && document.msHidden) ||
        (typeof document.webkitHidden !== 'undefined' && document.webkitHidden)
      )
    }
  }

  Flashing.propTypes = {
    agency: PropTypes.string.isRequired,
    entities: PropTypes.object.isRequired,
    items: PropTypes.array.isRequired
  }

  return Flashing
}
