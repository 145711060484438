import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { TYPES } from '../../constants/Modal'
import { closeModal } from '../../actions/modal'
import { requestExportCallback } from '../../actions/news'

class Modal extends Component {
  onClose = () => {
    this.props.dispatch(closeModal())
  }

  onSubmit = () => {
    const {
      type,
      dispatch,
      config: {
        exportData,
        news
      }
    } = this.props

    const input = this.refs.message

    switch (type) {
      case TYPES.EXPORT_COMMENT:
        if (exportData && news && input) {
          if (input.value && input.value.length > 0) {
            exportData['message'] = input.value
          }
          dispatch(requestExportCallback(exportData, news))
        } else {
          throw Error('Export comment require data')
        }
        break
      default:
        break
    }
    dispatch(closeModal())
  }

  render () {
    const {
      show,
      title,
      type
    } = this.props

    const styles = show ? { display: 'block' } : {}
    return (
      <div className='modal' role='dialog' style={styles}>
        <div className='modal-dialog'>
          <div className='modal-content'>
            <div className='modal-header'>
              <button type='button' className='close' onClick={this.onClose} aria-label='Close'>
                <span aria-hidden='true'>
                  &times;
                </span>
              </button>
              <h3 className='modal-title'>
                { title }
              </h3>
            </div>
            <div className='modal-body'>
              {
                type === TYPES.EXPORT_COMMENT && (
                  <div>
                    <h4 className='h4'>
                      <label htmlFor='message'>
                        Poznámka:
                        <span className='small'>
                          (nepovinné, max 140 znaků)
                        </span>
                      </label>
                    </h4>
                    <input name='message' ref='message' maxLength='140' className='form-control'/>
                  </div>
                )
              }
            </div>
            <div className='modal-footer'>
              <button
                type='button'
                className='btn btn-default'
                onClick={this.onClose}
              >
                Zrušit
              </button>
              <button
                type='button'
                className='btn btn-primary'
                onClick={this.onSubmit}
              >
                Převzít
              </button>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

Modal.propTypes = {
  dispatch: PropTypes.func.isRequired,
  show: PropTypes.bool,
  type: PropTypes.string,
  title: PropTypes.string,
  config: PropTypes.object
}

Modal.defaultProps = {
  type: TYPES.EXPORT_COMMENT
}

export default Modal
