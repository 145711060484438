import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { LAYOUT_FLAGS } from '../../constants/Layout'
import { toggleLayoutFlag } from '../../actions/layout'
import { togglePause, requestNewsListNextPage } from '../../actions/news'
import List from './List'

class NewsList extends Component {
  onTogglePause = () => {
    const {
      dispatch
    } = this.props
    dispatch(togglePause())
  }

  onToggleLayoutClick = flag => {
    const {
      dispatch
    } = this.props
    dispatch(toggleLayoutFlag(flag))
  }

  onToggleLeftPanel = () => {
    this.onToggleLayoutClick(LAYOUT_FLAGS.LEFT_PANEL)
  }

  onToggleDetailPosition = () => {
    this.onToggleLayoutClick(LAYOUT_FLAGS.DETAIL_POSITION)
  }

  onToggleDetail = () => {
    this.onToggleLayoutClick(LAYOUT_FLAGS.DETAIL)
  }

  /**
   *
   * @param mask
   * @returns {{className: string, toggleMenu: string, toggleDetail: string, detailIcon: string}}
   */
  getLayoutStyles (mask) {
    let containerClasses = 'main-content-center-block '
    let toggleMenu = 'glyphicon news-open-menu '
    let toggleDetail = 'glyphicon news-open-detail '
    let detailPositionIcon = 'glyphicon glyphicon-pause news-top-icon '

    switch (mask) {
      case LAYOUT_FLAGS.DETAIL:
        containerClasses += 'col-sm-8 col-lg-7 center-wide center-full-height'
        toggleMenu += 'glyphicon-forward menu'
        toggleDetail += 'glyphicon-forward'
        detailPositionIcon += 'news-setview'
        break
      case LAYOUT_FLAGS.LEFT_PANEL | LAYOUT_FLAGS.DETAIL:
        containerClasses += 'col-sm-6'
        toggleMenu += 'glyphicon-backward menu-active'
        toggleDetail += 'glyphicon-forward'
        detailPositionIcon += 'news-setview'
        break
      case LAYOUT_FLAGS.LEFT_PANEL:
        containerClasses += 'col-sm-9 col-lg-10 center-wide center-full-height'
        toggleMenu += 'glyphicon-backward menu-active'
        toggleDetail += 'glyphicon-backward'
        detailPositionIcon += 'news-setview'
        break
      case LAYOUT_FLAGS.LEFT_PANEL | LAYOUT_FLAGS.DETAIL_POSITION:
        containerClasses += 'col-sm-9 col-lg-10 center-wide center-full-height'
        toggleMenu += 'glyphicon-backward menu-active'
        toggleDetail += 'glyphicon-backward-vertical'
        detailPositionIcon += 'news-setview-vertical'
        break
      case LAYOUT_FLAGS.DETAIL | LAYOUT_FLAGS.DETAIL_POSITION:
        containerClasses += 'col-sm-12 center-wide'
        toggleMenu += 'glyphicon-forward menu'
        toggleDetail += 'glyphicon-forward-vertical'
        detailPositionIcon += 'news-setview-vertical'
        break
      case LAYOUT_FLAGS.LEFT_PANEL | LAYOUT_FLAGS.DETAIL | LAYOUT_FLAGS.DETAIL_POSITION:
        containerClasses += 'col-sm-9 col-lg-10 center-wide'
        toggleMenu += 'glyphicon-backward menu-active'
        toggleDetail += 'glyphicon-forward-vertical'
        detailPositionIcon += 'news-setview-vertical'
        break
      case LAYOUT_FLAGS.DETAIL_POSITION:
        containerClasses += 'col-sm-12 center-wide center-full-height'
        toggleMenu += 'glyphicon-forward menu'
        toggleDetail += 'glyphicon-backward-vertical'
        detailPositionIcon += 'news-setview-vertical'
        break
      default: // mask = 0
        containerClasses += 'col-sm-12 center-wide center-full-height'
        toggleMenu += 'glyphicon-forward menu'
        toggleDetail += 'glyphicon-backward'
        detailPositionIcon += 'news-setview'
        break
    }

    return {
      className: containerClasses,
      toggleMenu: toggleMenu,
      toggleDetail: toggleDetail,
      detailIcon: detailPositionIcon
    }
  }

  render () {
    const {
      isAppendingList,
      isFetchingList,
      isPaused,
      layout,
      reload
    } = this.props

    const {
      className,
      detailIcon,
      toggleMenu,
      toggleDetail
    } = this.getLayoutStyles(layout.mask)

    return (
      <div className={className} onScroll={this.onScroll}>
        <div className={`col-sm-12 news${isFetchingList ? ' lazyload' : ''}${!isAppendingList ? ' refr' : ''}`}>
          <div className='col-sm-12 news-top'/>
          <div
            className={`glyphicon news-pause news-top-icon ${isPaused ? 'glyphicon-play' : 'glyphicon-stop'}`}
            title='Stop/Play autoload'
            onClick={this.onTogglePause}
          />
          <div
            className={`glyphicon glyphicon-${isFetchingList ? 'transfer' : 'refresh'} news-top-icon news-reload`}
            onClick={!isFetchingList ? reload : null}
            title={isFetchingList ? 'Probíhá načítání dat...' : 'Refresh (použijí se aktualně nastavené filtry)'}
          />
          <div className={detailIcon} title='Přepnout umístění detailu' onClick={this.onToggleDetailPosition}/>
          <div className={toggleDetail} title='Otevřít/zavřít detail' onClick={this.onToggleDetail}/>
          <div className={toggleMenu} title='Otevřít/zavřít menu' onClick={this.onToggleLeftPanel}/>
          <List {...this.props}/>
        </div>
      </div>
    )
  }
}

NewsList.propTypes = {
  dispatch: PropTypes.func.isRequired,
  news: PropTypes.array,
  list: PropTypes.array,
  isAppendingList: PropTypes.bool,
  isFetchingList: PropTypes.bool,
  isPaused: PropTypes.bool,
  layout: PropTypes.object,
  onScrollBottomFunc: PropTypes.func,
  onScrollTopFunc: PropTypes.func,
  onScrollFunc: PropTypes.func
}

function mapStateToProps (state) {
  const {
    news
  } = state
  return {
    isAppendingList: news.isAppendingList,
    isFetchingList: news.isFetchingList,
    isPaused: news.isPaused,
    layout: state.layout,
    onScrollBottomFunc: requestNewsListNextPage.bind(null, state.queryFilter),
    onScrollTopFunc: null,
    onScrollFunc: null
  }
}

export default connect(mapStateToProps)(NewsList)
