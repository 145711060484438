import fetch from 'isomorphic-fetch'
import { Schemas } from '../constants/Schemas'
import { ACTIONS, MERGE_STYLES, AGENCIES, AGENCIES_PATHS } from '../constants/News'
import { constructFilteredQuery, getApiUrl } from '../utils/News'
import { normalize, schema } from 'normalizr'
import { getRequestParams } from '../utils/RequestFactory'
import { loggedOut } from './user'
import _ from 'lodash'
import queryString from 'query-string'

/**
 * Default merge style - replace for now
 *
 * @type {string}
 */
const defaultMergeStyle = MERGE_STYLES.REPLACE
const defaultItemsLimit = 50

export function requestNewsList (mergeStyle) {
  switch (mergeStyle) {
    case MERGE_STYLES.APPEND:
      return { type: ACTIONS.REQUEST_FILTERED_APPEND }
    default:
      return { type: ACTIONS.REQUEST_FILTERED }
  }
}

function requestDetail (id) {
  return {
    type: ACTIONS.REQUEST_SINGLE,
    id: id
  }
}

function requestDetailRelated (id) {
  return {
    type: ACTIONS.REQUEST_SINGLE_RELATED,
    id: id
  }
}

/**
 * Action creator for reset list
 *
 * !!! don't use it while reloading list, cos it redraw twice (to empty and then to new result => ugly blink)
 *
 * @returns {{type: string}}
 */
export function resetList () {
  return {
    type: ACTIONS.RESET_LIST
  }
}

/**
 * Action creator for newslist receive
 *
 * @param items
 * @param pagination
 * @param entities
 * @param mergeStyle
 * @param agency
 * @returns {{type: string, items: *, pagination: *, entities: *}}
 */
function receiveNewsList (items, pagination, entities, mergeStyle = defaultMergeStyle, agency) {
  let type = ACTIONS.RECEIVE_FILTERED
  switch (mergeStyle) {
    case MERGE_STYLES.APPEND:
      type = ACTIONS.RECEIVE_FILTERED_APPEND
      break
    case MERGE_STYLES.PREPEND:
      type = ACTIONS.RECEIVE_FILTERED_PREPEND
      break
    case MERGE_STYLES.REPLACE:
    case MERGE_STYLES.DEFAULT:
    default:
      type = ACTIONS.RECEIVE_FILTERED
      break
  }

  return {
    agency,
    entities,
    flashes: getFlashesFromNews(entities, agency),
    items,
    pagination,
    type
  }
}

function getFlashesFromNews (entities, agency) {
  let flashes = []
  if (agency === AGENCIES.CTK) {
    flashes = _.filter(entities.news, (news) => {
      return (news.priority === 2 && (Array.isArray(news.keywords) && news.keywords.indexOf('FLEŠ') >= 0))
    }).map(news => {
      return news.unique_id
    })
  }
  if (agency === AGENCIES.REUTERS) {
    flashes = _.filter(entities.news, (news) => {
      return (news.special_type === 'BULLETIN')
    }).map(news => {
      return news.unique_id
    })
  }
  return flashes
}

/**
 *
 * @param error
 * @returns {{type: string, error: *}}
 */
function receiveNewsListFailed (error) {
  return {
    type: ACTIONS.RECEIVE_FILTERED_FAILED,
    error: error.toString()
  }
}

/**
 * Action creator for receive news detail
 *
 * @param json
 * @param agency
 * @returns {{type: string, detail: *}}
 */
function receiveNewsDetail (json, agency) {
  return {
    type: ACTIONS.RECEIVE_SINGLE,
    detail: json,
    agency: agency
  }
}

function receiveNewsDetailFailed (error) {
  return {
    type: ACTIONS.RECEIVE_SINGLE_FAILED,
    error: error
  }
}

/**
 * Action creator for receive news detail related
 *
 * @param json
 * @returns {{type: string, detail: *}}
 */
function receiveDetailRelated (json) {
  return {
    type: ACTIONS.RECEIVE_SINGLE_RELATED,
    detail: json
  }
}

function receiveDetailRelatedFailed (error) {
  return {
    type: ACTIONS.RECEIVE_SINGLE_RELATED_FAILED,
    error: error
  }
}

/**
 * @param offset
 * @param limit
 * @param mergeStyle
 * @returns {Function}
 */
function fetchNewsList (offset, limit, mergeStyle = defaultMergeStyle) {
  return (dispatch, getState) => {
    dispatch(requestNewsList(mergeStyle))
    const {
      news: {
        agency,
        detail: {
          unique_id: detailId
        },
        detailAgency,
        newListNeeded
      },
      queryFilter
    } = getState()
    const filters = queryFilter.toJS()
    const url = getApiUrl() + '/api/news/' + AGENCIES_PATHS[agency] + '/list'
    const params = Object.assign({}, getRequestParams(), {
      method: 'post',
      body: JSON.stringify(constructFilteredQuery(filters[agency], offset, limit))
    })
    let result = []
    return fetch(url, params)
      .then(response => {
        if (response.status === 401) {
          throw Error('401')
        }
        return response.json()
      })
      .then(json => {
        const {
          items,
          paginator
        } = json
        const normalized = normalize(items, new schema.Array(Schemas[agency]))
        result = normalized.result.reduce((arr, newsId) => {
          if (arr.indexOf(newsId) === -1) {
            arr.push(newsId)
          }
          return arr
        }, [])
        dispatch(receiveNewsList(result, paginator, normalized.entities, mergeStyle, agency))
      })
      .then(() => {
        let promises = []
        if (result.length > 0 && (!detailId || detailAgency !== agency)) {
          const parsedQuery = typeof location !== 'undefined' && location.search ? queryString.parse(location.search) : {}
          if (parsedQuery.detail && parsedQuery.agency && AGENCIES[parsedQuery.agency.toUpperCase()] && !detailId) {
            dispatch(changeAgency(AGENCIES[parsedQuery.agency.toUpperCase()]))
            promises.push(dispatch(requestNewsDetail(parsedQuery.detail)))
          } else {
            promises.push(dispatch(requestNewsDetail(result[0])))
          }
        }
        if (newListNeeded) {
          promises.push(dispatch(requestNewsListIfNeeded()))
        }
        return Promise.all(promises)
      })
      .catch(err => {
        if (err.message === '401') {
          dispatch(loggedOut())
        }
        dispatch(receiveNewsListFailed(err))
      })
  }
}

function fetchDetail (id, useFilter = false) {
  return (dispatch, getState) => {
    dispatch(requestDetail(id))
    const state = getState()
    const filters = state.queryFilter.toJS()
    const agency = state.news.agency
    const url = getApiUrl() + '/api/news/' + AGENCIES_PATHS[agency] + '/' + id
    const params = Object.assign({}, getRequestParams(), {
      method: 'post',
      body: JSON.stringify(state.queryFilter && useFilter ? constructFilteredQuery(filters[agency], 0, 1) : {})
    })
    return fetch(url, params)
      .then(response => {
        if (response.status === 401) {
          throw Error('401')
        }
        return response.json()
      })
      .then(json => {
        dispatch(receiveNewsDetail(json, agency))
      })
      .then(() => {
        let promises = []
        if (agency === AGENCIES.CTK) {
          promises.push(dispatch(fetchDetailRelated(id)))
        }
        return Promise.all(promises)
      })
      .catch(err => {
        if (err.message === '401') {
          dispatch(loggedOut())
        }
        dispatch(receiveNewsDetailFailed(err))
      })
  }
}

function fetchDetailRelated (id) {
  return dispatch => {
    dispatch(requestDetailRelated(id))
    const url = getApiUrl() + '/api/news/ctk/' + id + '/related'
    const params = getRequestParams()
    return fetch(url, params)
      .then(response => {
        if (response.status === 401) {
          throw Error('401')
        }
        return response.json()
      })
      .then(json => dispatch(receiveDetailRelated(json)))
      .catch(err => {
        if (err.message === '401') {
          dispatch(loggedOut())
        }
        dispatch(receiveDetailRelatedFailed(err))
      })
  }
}

/**
 * Do we need to fetch newslist?
 * used by autorefresh
 *
 * @param state
 * @returns {boolean}
 */
function shouldFetchNewsList (state) {
  const {
    isFetchingList,
    isPaused
  } = state.news

  return !(isFetchingList || isPaused)
}

/**
 * Should we fetch next page?
 *
 * @param state
 * @returns {boolean}
 */
function shouldFetchNextPage (state) {
  const {
    isFetchingList
  } = state.news

  return !isFetchingList
}

function shouldFetchNewsDetail (state, id) {
  return !!id
}

/**
 * Action creator for toggling pause
 *
 * @returns {{type: string}}
 */
export function togglePause () {
  return {
    type: ACTIONS.TOGGLE_PAUSE
  }
}

/**
 * Action creator for toggling flasher
 *
 * @returns {{type: string}}
 */
export function toggleFlasher () {
  return {
    type: ACTIONS.TOGGLE_FLASHER
  }
}

/**
 * Action creator for toggling flasher sound
 *
 * @returns {{type: string}}
 */
export function toggleFlasherSound () {
  return {
    type: ACTIONS.TOGGLE_FLASHER_SOUND
  }
}

/**
 * Action creator for marking flash messages as seen
 *
 * @param flashes array news ids that will be marked
 * @returns {{type: string, flashes: *}}
 */
export function setFlashesSeen (flashes) {
  return {
    type: ACTIONS.SET_FLASHES_SEEN,
    flashes: flashes
  }
}

/**
 * Action creator for setting flashing state
 *
 * @param value boolean
 * @returns {{type: string, flashing: boolean}}
 */
export function setFlashing (value = true) {
  return {
    type: ACTIONS.SET_FLASHING,
    flashing: value
  }
}

function shouldExport (state) {
  return !state.exports.isExporting
}

export function requestExportCallback (exportData, news, clickCode) {
  return (dispatch, getState) => {
    if (shouldExport(getState())) {
      return dispatch(exportCallback(exportData, news, clickCode))
    }
  }
}

/**
 * Action creator for calling export callback
 *
 */
function exportCallback (exportData, news, clickCode) {
  return (dispatch, getState) => {
    const state = getState()
    dispatch(requestExport(news))
    const agency = state.news.agency
    const url = getApiUrl() + '/api/news/' + AGENCIES_PATHS[agency] + '/' + news.unique_id + '/exports'
    const body = {
      name: exportData.name,
      user: state.user.data.email
    }
    if (exportData.message) {
      body.message = exportData.message
    }
    const params = Object.assign({}, getRequestParams(), {
      method: 'post',
      body: JSON.stringify(body)
    })
    return fetch(url, params).then(function (response) {
      if (response.status === 201) {
        dispatch(receiveExport(news))
        dispatch(requestNewsDetail(news.unique_id))
        if (_.isFunction(exportData.callback)) {
          exportData.callback(news, clickCode)
        }
      } else dispatch(receiveExportFailed(news, 'Response status ' + response.status))
    }).catch(function (ex) {
      dispatch(receiveExportFailed(news, ex))
    })
  }
}

export function deleteExport (news, exportItem) {
  return (dispatch, getState) => {
    const state = getState()
    const agency = state.news.agency
    const url = getApiUrl() + '/api/news/' + AGENCIES_PATHS[agency] + '/' + news.unique_id + '/exports/' + exportItem.id
    const params = Object.assign({}, getRequestParams(), {
      method: 'delete'
    })
    return fetch(url, params).then(function (response) {
      if (response.status === 204 || response.status === 200) {
        dispatch(requestNewsDetail(news.unique_id))
      } else {
        window.alert('Nepodařilo se smazat záznam: ' + response.status)
      }
    }).catch(function (ex) {
      window.alert('Nepodařilo se smazat záznam: ' + ex.message)
    })
  }
}

function requestExport (news) {
  return {
    type: ACTIONS.REQUEST_EXPORT,
    news: news
  }
}

function receiveExport (news) {
  return {
    type: ACTIONS.RECEIVE_EXPORT,
    news: news
  }
}

function receiveExportFailed (news, reason) {
  return {
    type: ACTIONS.RECEIVE_EXPORT_FAILED,
    news: news,
    reason: reason
  }
}

function newListNeeded () {
  return {
    type: ACTIONS.REQUEST_FILTERED_NEEDED
  }
}

/**
 * Public method for creating new request for NewsList
 * completely based on current state
 *
 * @returns {Function}
 */
export function requestNewsListIfNeeded (mergeStyle = defaultMergeStyle, offset = 0, limit = defaultItemsLimit) {
  if (!_.includes(MERGE_STYLES, mergeStyle)) {
    throw new Error('Unknown merge style requested' + mergeStyle)
  }
  return (dispatch, getState) => {
    const state = getState()
    if (shouldFetchNewsList(state)) {
      return dispatch(fetchNewsList(offset, limit, mergeStyle))
    } else {
      return dispatch(newListNeeded())
    }
  }
}

/**
 * Action creator for requesting news list next page
 *
 * @returns {Function}
 * @see shouldFetchNextPage, fetchNewsList
 */
export function requestNewsListNextPage () {
  return (dispatch, getState) => {
    const state = getState()
    const {
      news: {
        items,
        agency
      }
    } = state
    if (shouldFetchNextPage(state)) {
      return dispatch(fetchNewsList(items[agency].length ? items[agency].length : 0, defaultItemsLimit, MERGE_STYLES.APPEND))
    }
  }
}

/**
 * Action creator for requesting news detail
 *
 * @param id
 * @returns {Function}
 */
export function requestNewsDetail (id) {
  return (dispatch, getState) => {
    if (shouldFetchNewsDetail(getState(), id)) {
      return dispatch(fetchDetail(id, true))
    }
  }
}

export function requestNewsRelatedDetail (id) {
  return (dispatch, getState) => {
    if (shouldFetchNewsDetail(getState(), id)) {
      return dispatch(fetchDetail(id))
    }
  }
}

/**
 * Action creator for changing agency
 *
 * @returns {{agency: string}}
 */
export function changeAgency (agency) {
  return {
    type: ACTIONS.CHANGE_AGENCY,
    agency
  }
}

export default {
  requestNewsListIfNeeded,
  requestNewsListNextPage,
  toggleFlasher,
  setFlashesSeen,
  setFlashing,
  requestNewsDetail,
  requestNewsRelatedDetail,
  changeAgency
}
