import '@babel/polyfill'
/** React */
import React from 'react'
import { render } from 'react-dom'
/** Redux */
import { createStore, applyMiddleware, compose } from 'redux'
import { Provider } from 'react-redux'
import thunkMiddleware from 'redux-thunk'
import persistState from 'redux-localstorage'
import { PersistConfig } from './utils/State'
/** Application */
import reducers from './reducers'
import App from './components/App'

/** Bootstrap */
const defaults = {
  containerId: 'cia-app',
  export: {
    'ctk': [],
    'reuters': [],
    'reuters_photos': []
  },
  newsApi: 'https://agentury.economia.cz'
}

let cia_config = window.cia_config || {}
if (cia_config.export && cia_config.export instanceof Array) {
  cia_config.export = {
    'ctk': cia_config.export
  }
}
const c = Object.assign({}, defaults, cia_config)
Object.assign(cia_config, c)

const createStoreWithMiddleware = applyMiddleware(
  thunkMiddleware
)(createStore)

const createPersistentStore = compose(
  persistState(null, PersistConfig)
)(createStoreWithMiddleware)

const store = createPersistentStore(reducers)

const container = document.getElementById(cia_config.containerId)
container.style.height = '100%'

render(
  <Provider store={store}>
    <App/>
  </Provider>,
  container
)
