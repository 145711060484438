import React, { Component } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { EXPORT_BTN_SETTINGS } from '../../constants/News'
import { deleteExport } from '../../actions/news'

class ExportLogItem extends Component {
  handleDeleteClick = () => {
    const {
      dispatch,
      item,
      news
    } = this.props

    dispatch(deleteExport(news, item))
  }

  render () {
    const {
      item
    } = this.props

    return (
      <tr style={{ color: EXPORT_BTN_SETTINGS[item.name] ? EXPORT_BTN_SETTINGS[item.name].color : '' }}>
        <td>
          {
            (item.name === 'hn' || item.name === 'výchozí') && (
              <span onClick={this.handleDeleteClick} aria-label='Close'>
                <span aria-hidden='true' className='close-cross'>
                  &times;
                </span>
              </span>
            )
          }
        </td>
        <td>
          { item.name }
        </td>
        <td>
          { item.user }
        </td>
        <td>
          { moment(item.date).format('DD.MM.YYYY HH:mm:ss') }
        </td>
        <td>
          { item.message }
        </td>
      </tr>
    )
  }
}

ExportLogItem.propTypes = {
  dispatch: PropTypes.func.isRequired,
  item: PropTypes.object,
  news: PropTypes.object
}

export default ExportLogItem
