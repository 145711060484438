import { merge, uniq } from 'lodash'
import { ACTIONS, AGENCIES } from '../constants/News'

export const initialState = {
  [AGENCIES.CTK]: {
    news: {}
  },
  [AGENCIES.REUTERS]: {
    news: {}
  },
  [AGENCIES.REUTERS_PHOTOS]: {
    news: {}
  }
}

export default function entities (state = initialState, action) {
  switch (action.type) {
    case ACTIONS.RECEIVE_SINGLE:
      if (action.detail && state[action.agency].news[action.detail.unique_id]) {
        state[action.agency].news[action.detail.unique_id].exports = uniq(action.detail.exports.map(exp => exp.name))
      }
      return state
    default:
      if (action.entities && action.agency) {
        return merge({}, state, {
          [action.agency]: action.entities
        })
      }
      return state
  }
}
