import Immutable, { Map } from 'immutable'
import {
  ACTIONS,
  FILTERS_CTK,
  FILTERS_REUTERS,
  FILTERS_REUTERS_PHOTOS,
  GROUPS_CTK,
  GROUPS_REUTERS,
  GROUPS_REUTERS_PHOTOS,
  PLACEMENT
} from '../constants/QueryFilter'
import { AGENCIES } from '../constants/News'

/* Skip warning for iterable.length */
Immutable.Iterable.noLengthWarning = true

export function getInitialState () {
  return Map({
    [AGENCIES.CTK]: {
      Groups: GROUPS_CTK,
      ALL_FILTERS: FILTERS_CTK,
      [PLACEMENT.TOP]: getFiltersForPlacement(FILTERS_CTK, PLACEMENT.TOP),
      [PLACEMENT.LEFT]: getFiltersForPlacement(FILTERS_CTK, PLACEMENT.LEFT)
    },
    [AGENCIES.REUTERS]: {
      Groups: GROUPS_REUTERS,
      ALL_FILTERS: FILTERS_REUTERS,
      [PLACEMENT.TOP]: getFiltersForPlacement(FILTERS_REUTERS, PLACEMENT.TOP),
      [PLACEMENT.LEFT]: getFiltersForPlacement(FILTERS_REUTERS, PLACEMENT.LEFT)
    },
    [AGENCIES.REUTERS_PHOTOS]: {
      Groups: GROUPS_REUTERS_PHOTOS,
      ALL_FILTERS: FILTERS_REUTERS_PHOTOS,
      [PLACEMENT.TOP]: getFiltersForPlacement(FILTERS_REUTERS_PHOTOS, PLACEMENT.TOP),
      [PLACEMENT.LEFT]: getFiltersForPlacement(FILTERS_REUTERS_PHOTOS, PLACEMENT.LEFT)
    }
  })
}

function getFiltersForPlacement (filters, placement) {
  return filters.filter(f => f.placement === placement)
}

function setValue (state, action) {
  const {
    filter,
    value
  } = action
  filter.value = value
  return state
}

function setChecked (state, action) {
  const {
    filters,
    checked
  } = action
  filters.forEach(filter => {
    filter.checked = checked
  })
  return state
}

export default function queryFilters (state = getInitialState(), action) {
  switch (action.type) {
    case ACTIONS.SET_VALUE:
      return setValue(state, action)
    case ACTIONS.SET_CHECKED:
      return setChecked(state, action)
    default:
      return state
  }
}
