import { ACTIONS, LAYOUT_FLAGS } from '../constants/Layout'
import { ACTIONS as NEWS_ACTIONS } from '../constants/News'
import { toggleFlagInMask } from '../utils/binary'

export function getInitialState () {
  return {
    mask: 6
  }
}

export default function layout (state = getInitialState(), action) {
  switch (action.type) {
    case ACTIONS.TOGGLE_LAYOUT_FLAG:
      return Object.assign({}, state, {
        mask: toggleFlagInMask(state.mask, action.flag)
      })
    case NEWS_ACTIONS.REQUEST_SINGLE:
      return Object.assign({}, state, {
        mask: state.mask | LAYOUT_FLAGS.DETAIL
      })
    default:
      return state
  }
}
