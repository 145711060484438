import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { requestExportCallback } from '../../actions/news'
import { openModal } from '../../actions/modal'
import { EXPORT_BTN_SETTINGS } from '../../constants/News'
import { TYPES } from '../../constants/Modal'

class Export extends Component {
  handleClick = (event) => {
    const {
      detail,
      dispatch,
      exportData
    } = this.props

    let clickCode = 0

    if (event.button && (event.button === 1 || event.button === 2)) {
      clickCode = event.button
    }

    dispatch(requestExportCallback(exportData, detail, clickCode))
  }

  handleOpenModal = () => {
    const {
      detail,
      dispatch,
      exportData
    } = this.props

    const modalConfig = {
      title: 'Převzít zprávu pro tištěné HN?',
      type: TYPES.EXPORT_COMMENT,
      config: {
        exportData,
        news: detail
      }
    }

    dispatch(openModal(modalConfig))
  }

  render () {
    const {
      exportData,
      modal
    } = this.props

    return (
      <button
        className='btn-sm btn-default export-btn'
        style={{ background: EXPORT_BTN_SETTINGS[exportData.name] ? EXPORT_BTN_SETTINGS[exportData.name].color : '' }}
        onClick={modal ? this.handleOpenModal : this.handleClick}
      >
        { exportData.title ? exportData.title : exportData.name }
      </button>
    )
  }
}

Export.propTypes = {
  exportData: PropTypes.object,
  detail: PropTypes.object,
  modal: PropTypes.bool
}

export default connect()(Export)
