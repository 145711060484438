import { schema } from 'normalizr'
import { AGENCIES } from './News'

const newsSchema = new schema.Entity('news', {}, { idAttribute: 'unique_id' })

const schemas = {}

Object.values(AGENCIES).map(agency => {
  schemas[agency] = newsSchema
})

export const Schemas = schemas
