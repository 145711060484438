import { ACTIONS, AGENCIES } from '../constants/News'
import _ from 'lodash'

export function getInitialState () {
  return {
    isFetchingList: false,
    newListNeeded: false,
    isAppendingList: false,
    isFetchingDetail: false,
    isFetchingRelated: false,
    isPaused: false,
    items: {
      [AGENCIES.CTK]: [],
      [AGENCIES.REUTERS]: [],
      [AGENCIES.REUTERS_PHOTOS]: []
    },
    detail: {},
    flashes: [],
    flashesSeen: [],
    isFlashing: false,
    flasher: true,
    flasherSound: true,
    related: [],
    agency: AGENCIES.CTK,
    detailAgency: null
  }
}

function newsList (state = getInitialState(), action) {
  switch (action.type) {
    case ACTIONS.REQUEST_FILTERED:
      return Object.assign({}, state, {
        isFetchingList: true
      })
    case ACTIONS.REQUEST_FILTERED_APPEND:
      return Object.assign({}, state, {
        isAppendingList: true,
        isFetchingList: true
      })
    case ACTIONS.RECEIVE_FILTERED:
      return Object.assign({}, state, {
        isFetchingList: false,
        newListNeeded: false,
        items: Object.assign({}, state.items, { [action.agency]: action.items }),
        flashes: _.filter(action.flashes, (id) => {
          return (state.flashesSeen.indexOf(id) < 0)
        })
      })
    case ACTIONS.RECEIVE_FILTERED_APPEND:
      return Object.assign({}, state, {
        isAppendingList: false,
        isFetchingList: false,
        items: Object.assign({}, state.items, { [action.agency]: _.union(state.items[action.agency], action.items) }),
        flashes: _.filter(action.flashes, (id) => {
          return (state.flashesSeen.indexOf(id) < 0)
        })
      })
    case ACTIONS.RECEIVE_FILTERED_PREPEND:
      return Object.assign({}, state, {
        isFetchingList: false,
        items: Object.assign({}, state.items, { [action.agency]: _.union(action.items, state.items[action.agency]) }),
        flashes: _.filter(action.flashes, (id) => {
          return (state.flashesSeen.indexOf(id) < 0)
        })
      })
    case ACTIONS.RECEIVE_FILTERED_FAILED:
      return Object.assign({}, state, {
        isFetchingList: false
      })
    default:
      return state
  }
}

function newsDetail (state = getInitialState(), action) {
  switch (action.type) {
    case ACTIONS.RECEIVE_SINGLE:
      return Object.assign({}, state, {
        detail: action.detail,
        isFetchingDetail: false,
        detailAgency: action.agency,
        related: []
      })
    case ACTIONS.RECEIVE_SINGLE_RELATED:
      return Object.assign({}, state, {
        related: action.detail.items,
        isFetchingRelated: false
      })
    case ACTIONS.REQUEST_SINGLE_RELATED:
      return Object.assign({}, state, {
        isFetchingRelated: true
      })
    case ACTIONS.REQUEST_SINGLE:
      return Object.assign({}, state, {
        isFetchingDetail: true
      })
    default:
      return state
  }
}

export default function news (state = getInitialState(), action) {
  const {
    isPaused,
    flasher,
    flasherSound
  } = state

  switch (action.type) {
    case ACTIONS.REQUEST_FILTERED:
    case ACTIONS.REQUEST_FILTERED_APPEND:
    case ACTIONS.RECEIVE_FILTERED:
    case ACTIONS.RECEIVE_FILTERED_APPEND:
    case ACTIONS.RECEIVE_FILTERED_PREPEND:
    case ACTIONS.RECEIVE_FILTERED_FAILED:
      return newsList(state, action)
    case ACTIONS.REQUEST_SINGLE:
    case ACTIONS.RECEIVE_SINGLE:
    case ACTIONS.RECEIVE_SINGLE_RELATED:
    case ACTIONS.REQUEST_SINGLE_RELATED:
      return newsDetail(state, action)
    case ACTIONS.TOGGLE_PAUSE:
      return Object.assign({}, state, {
        isPaused: !isPaused
      })
    case ACTIONS.TOGGLE_FLASHER:
      return Object.assign({}, state, {
        flasher: !flasher
      })
    case ACTIONS.TOGGLE_FLASHER_SOUND:
      return Object.assign({}, state, {
        flasherSound: !flasherSound
      })
    case ACTIONS.RESET_LIST:
      return Object.assign({}, state, {
        items: []
      })
    case ACTIONS.SET_FLASHING:
      return Object.assign({}, state, {
        isFlashing: action.flashing
      })
    case ACTIONS.SET_FLASHES_SEEN:
      return Object.assign({}, state, {
        flashesSeen: _.union(state.flashesSeen, action.flashes),
        flashes: [],
        isFlashing: false
      })
    case ACTIONS.REQUEST_FILTERED_NEEDED: {
      return Object.assign({}, state, {
        newListNeeded: true
      })
    }
    case ACTIONS.CHANGE_AGENCY: {
      return Object.assign({}, state, {
        agency: action.agency
      })
    }
    default:
      return state
  }
}
