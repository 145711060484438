import { ACTIONS } from '../constants/Modal'

export function openModal (config = {}) {
  return {
    type: ACTIONS.OPEN,
    config: config
  }
}

export function closeModal () {
  return {
    type: ACTIONS.CLOSE
  }
}

export default {
  openModal,
  closeModal
}
