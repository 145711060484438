import moment from 'moment'

export function constructFilteredQuery (filters, offset = 0, limit = 30) {
  const query = {
    offset: offset,
    limit: limit
  }

  filters.ALL_FILTERS.forEach(filter => {
    if (filter.value && moment.isMoment(filter.value)) {
      query[filter.name] = filter.value.format(filter.format)
    } else {
      if (filter.type === 'Checkbox' && filter.checked) {
        if (!Array.isArray(query[filter.name])) query[filter.name] = []
        query[filter.name].push(filter.value)
      }
      if (filter.type !== 'Checkbox' && filter.value) {
        query[filter.name] = filter.value
      }
    }
  })
  return query
}

export function getApiUrl () {
  return cia_config.newsApi
}
