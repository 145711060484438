import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { toggleFlasherSound } from '../../actions/news'
import { connect } from 'react-redux'

class FlasherSound extends Component {
  handleFlasherSoundChange = () => {
    const {
      dispatch
    } = this.props

    dispatch(toggleFlasherSound())
  }

  render () {
    const {
      flasherSound
    } = this.props

    return (
      <div className='col-sm-6'>
        <span
          className={`glyphicon head-icon ${flasherSound ? 'glyphicon-volume-up' : 'glyphicon-volume-off'}`}
          onClick={this.handleFlasherSoundChange}
        />
      </div>
    )
  }
}

FlasherSound.propTypes = {
  dispatch: PropTypes.func.isRequired,
  flasherSound: PropTypes.bool.isRequired
}

function mapStateToProps (state) {
  const {
    flasherSound
  } = state.news
  return {
    flasherSound: flasherSound
  }
}

export default connect(mapStateToProps)(FlasherSound)
