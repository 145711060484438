import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Spinner from 'react-loader'

class ButtonLoader extends Component {
  getColor () {
    if (this.props.disabled) {
      return this.props.spinColorDisabled
    } else if (this.props.primary || this.props.secondary) {
      return this.props.spinColorLight
    } else {
      return this.props.spinColorDark
    }
  }

  handleClick = event => {
    if (event) {
      event.preventDefault()
    }

    this.props.onClick()
  }

  render () {
    const {
      icon,
      loading,
      spinConfig,
      style
    } = this.props

    const color = this.getColor()

    return (
      <span
        className={`btn btn-default${loading ? ' disabled' : ''}`}
        style={Object.assign({}, { color, paddingLeft: 30, position: 'relative' }, style)}
        onClick={this.handleClick}
      >
        {
          <span style={{ width: 25, height: 25, position: 'absolute', left: 0, top: 1 }}>
            {
              loading
                ? <Spinner ref='spinner' {...spinConfig} color={color} loaded={false}/>
                : (
                  <div color={color} style={{ width: 10, height: 10, marginLeft: '8px', verticalAlign: 'center' }}>
                    { icon }
                  </div>
                )
            }
          </span>
        }
        { this.props.children }
      </span>
    )
  }
}

ButtonLoader.propTypes = {
  icon: PropTypes.string,
  loading: PropTypes.bool,
  spinConfig: PropTypes.object,
  spinColorDark: PropTypes.string,
  spinColorLight: PropTypes.string,
  spinColorDisabled: PropTypes.string,
  children: PropTypes.node,
  onClick: PropTypes.func.isRequired,
  style: PropTypes.object
}

ButtonLoader.defaultProps = {
  icon: '▸',
  loading: false,
  spinConfig: {
    lines: 10,
    length: 5,
    width: 2,
    radius: 3
  },
  spinColorDark: '#444',
  spinColorLight: '#444',
  spinColorDisabled: '#999',
  children: <span>Submit</span>,
  style: {}
}

export default ButtonLoader
