import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import ButtonLoader from '../ButtonLoader'
import { login, requestValidation } from '../../actions/user'

class LoginScreen extends Component {
  componentDidMount () {
    const {
      dispatch,
      startup
    } = this.props

    if (startup) {
      dispatch(requestValidation)
    }
  }

  handleLoginClick = () => {
    const {
      dispatch
    } = this.props

    dispatch(login())
  }

  render () {
    const {
      loading
    } = this.props

    const btnStyles = {
      fontSize: '24px',
      paddingTop: '3px',
      paddingRight: '10px',
      paddingBottom: '3px'
    }
    const spinConfig = {
      lines: 10,
      length: 5,
      width: 2,
      radius: 3,
      top: '18px',
      left: '15px'
    }
    return (
      <div style={{
        'backgroundColor': '#428bca',
        color: '#FFF',
        height: '100%',
        padding: '20px',
        'textAlign': 'center',
        display: [
          '-webkit-box',
          '-webkit-flex',
          '-ms-flexbox',
          'flex'
        ],
        alignItems: 'center',
        justifyContent: 'center'
      }}>
        <div style={{ maxWidth: '600px', display: 'inline-block', flex: 1 }}>
          <h1>
            Agentury Economia a.s.
          </h1>
          <ButtonLoader
            onClick={this.handleLoginClick}
            loading={loading}
            style={btnStyles}
            spinConfig={spinConfig}
          >
            Přihlásit
          </ButtonLoader>
          <p style={{ fontSize: '1.5em', padding: '10px' }}>
            Pro úspěšné přihlášení je potřeba učet domény @economia.cz, @aktualne.cz nebo @respekt.cz
          </p>
          <img
            src={cia_config.newsApi + '/static/images/ctk_logo.png'}
            alt=''
            style={{ borderRadius: '15px', width: '450px' }}
          />
        </div>
      </div>
    )
  }
}

LoginScreen.propTypes = {
  dispatch: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  startup: PropTypes.bool.isRequired
}

function mapStateToProps (state) {
  return {
    loading: state.user.authInProgress,
    startup: state.user.startup
  }
}

export default connect(mapStateToProps)(LoginScreen)
