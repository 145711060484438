import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import moment from 'moment'
import { LAYOUT_FLAGS } from '../../constants/Layout'
import { REUTERS_PHOTOS_SECTIONS } from '../../constants/News'
import Highlightable from '../Highlightable'
import Export from './Export'
import ExportLog from './ExportLog'

class ReutersPhotosDetail extends Component {
  render () {
    const {
      detail,
      detailAgency,
      exports,
      layout,
      news
    } = this.props

    const https_original_image = detail.original_image.replace('http://', 'https://')
    const https_resized_image = detail.resized_image.replace('http://', 'https://')

    Object.assign(detail, {
      original_image: https_original_image,
      resized_image: https_resized_image
    })

    const detailWithType = {
      ...detail,
      type: 'photo'
    }

    const date = moment(detail.updated)
    const isFetchingDetail = news.isFetchingDetail
    let containerClasses = 'main-content-right-block'
    switch (layout.mask) {
      case LAYOUT_FLAGS.LEFT_PANEL | LAYOUT_FLAGS.DETAIL | LAYOUT_FLAGS.DETAIL_POSITION:
        containerClasses += ' col-sm-9 col-lg-10 right-wide'
        break
      case LAYOUT_FLAGS.DETAIL | LAYOUT_FLAGS.DETAIL_POSITION:
        containerClasses += ' col-sm-12 right-wide'
        break
    }

    return (
      <div className={containerClasses}>
        <div className={isFetchingDetail ? 'detail-loader' : 'loaded'}/>
        <div className='col-md-12 modal-container newstext'>
          <div className='export-buttons'>
            {
              (exports.data[detailAgency] && exports.data[detailAgency].length)
                ? exports.data[detailAgency].map(exportData =>
                  <Export exportData={exportData} detail={detailWithType} key={exportData.name} dispatch={this.props.dispatch}/>
                )
                : ''
            }
          </div>
          <div className='maindate'>
            { moment(date).format('DD.MM.YYYY HH:mm:ss') }
          </div>
          <h1>
            <Highlightable highlighted={detail.title_highlighted} normal={detail.title}/>
          </h1>
          <div className='detail-download-link-wrapper'>
            <a href={https_original_image} className='detail-download-link'>
              <img src={https_resized_image} alt={detail.title}/>
              <span>
                Stáhnout v plném rozlišení
              </span>
            </a>
          </div>
          <div
            dangerouslySetInnerHTML={{
              __html: detail.description_highlighted ? detail.description_highlighted : detail.description
            }}
          />
          <table className='table table-striped detailinfo'>
            <thead>
              <tr>
                <th colSpan={5}>
                  Info
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className='col-md-3'>
                  <strong>
                    Priorita:
                  </strong>
                </td>
                <td className='col-md-2'>
                  { detail.priority }
                </td>
              </tr>
              <tr>
                <td className='col-md-3'>
                  <strong>
                    Sekce:
                  </strong>
                </td>
                <td className='col-md-2'>
                  { REUTERS_PHOTOS_SECTIONS[detail.section] || 'Neznámá sekce' }
                </td>
              </tr>
              <tr>
                <td className='col-md-3'>
                  <strong>
                    Guid:
                  </strong>
                </td>
                <td className='col-md-2'>
                  { detail.guid }
                </td>
              </tr>
              <tr>
                <td className='col-md-3'>
                  <strong>
                    Revize:
                  </strong>
                </td>
                <td className='col-md-2'>
                  { detail.revision_id }
                </td>
              </tr>
              <tr>
                <td className='col-md-3'>
                  <strong>
                    Slugline:
                  </strong>
                </td>
                <td className='col-md-2'>
                  { detail.slugline }
                </td>
              </tr>
            </tbody>
          </table>
          {
            (detail.exports && detail.exports.length > 0) &&
            <ExportLog dispatch={this.props.dispatch} exports={detail.exports} news={detail}/>
          }
        </div>
      </div>
    )
  }
}

ReutersPhotosDetail.propTypes = {
  detail: PropTypes.object,
  detailAgency: PropTypes.string,
  exports: PropTypes.object,
  news: PropTypes.object,
  layout: PropTypes.object
}

function mapStateToProps (state) {
  const {
    exports,
    news,
    layout
  } = state

  return {
    exports,
    news,
    layout
  }
}

export default connect(mapStateToProps)(ReutersPhotosDetail)
