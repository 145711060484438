import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { setValue, setChecked } from '../../actions/queryFilter'

class CheckboxGroup extends Component {
  handleChange = event => {
    const {
      filter,
      dispatch
    } = this.props

    const checked = event.target.checked || event.target.value === 'on'
    dispatch(setChecked(filter.children, checked))
    dispatch(setValue(filter, null))
  }

  render () {
    const {
      filter,
      checked
    } = this.props

    return (
      <div>
        <div className={`col-sm-8 col-md-9 form-label level1${filter.parent ? 2 : 1}`}>
          { filter.label }
        </div>
        <div className='col-sm-4 col-md-3 chbholder'>
          <input type='checkbox' value='' onChange={this.handleChange} checked={checked}/>
        </div>
      </div>
    )
  }
}

CheckboxGroup.propTypes = {
  filter: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  checked: PropTypes.bool
}

export default connect()(CheckboxGroup)
