import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { REGIONS_CTK, DESKS_CTK, CATEGORIES_CTK, NEWS_TYPES_CTK } from '../../../constants/News'
import { LAYOUT_FLAGS } from '../../../constants/Layout'
import NewsDetailRelated from './related'
import moment from 'moment'
import Modal from '../../Modal'
import Export from '../Export'
import ExportLog from '../ExportLog'
import Highlightable from '../../Highlightable'

const regions = Object.assign({}, REGIONS_CTK['Celá ČR'], REGIONS_CTK['Svět mimo ČR'])

class CtkDetail extends Component {
  render () {
    const {
      detail,
      detailAgency,
      exports,
      layout,
      loadDetail,
      modal,
      news
    } = this.props

    const detailWithType = {
      ...detail,
      type: 'article'
    }

    const exportColors = {}
    exports.data[detailAgency].forEach(exportData => {
      exportColors[exportData.name] = exportData.color
    })
    const related = news.related
    const date = moment(detail.updated)
    const isFetchingRelated = news.isFetchingRelated
    const isFetchingDetail = news.isFetchingDetail
    let containerClasses = 'main-content-right-block'

    switch (layout.mask) {
      case LAYOUT_FLAGS.LEFT_PANEL | LAYOUT_FLAGS.DETAIL | LAYOUT_FLAGS.DETAIL_POSITION:
        containerClasses += ' col-sm-9 col-lg-10 right-wide'
        break
      case LAYOUT_FLAGS.DETAIL | LAYOUT_FLAGS.DETAIL_POSITION:
        containerClasses += ' col-sm-12 right-wide'
        break
    }

    return (
      <div className={containerClasses}>
        <div className={isFetchingDetail ? 'detail-loader' : 'loaded'}/>
        <div className='col-md-12 modal-container newstext'>
          <div className='export-buttons'>
            {
              (exports.data[detailAgency] && exports.data[detailAgency].length)
                ? exports.data[detailAgency].map(exportData =>
                  <Export exportData={exportData} detail={detailWithType} key={exportData.name} dispatch={this.props.dispatch}/>
                )
                : ''
            }
            <Export
              exportData={{ name: 'hn', title: 'HN' }}
              detail={detail}
              key='modal-test'
              modal={true}
              dispatch={this.props.dispatch}
            />
          </div>
          <div className='maindate'>
            { moment(date).format('DD.MM.YYYY HH:mm:ss') }
          </div>
          <div className='region'>
            { (detail.region && regions[detail.region]) ? regions[detail.region] : detail.region }
          </div>
          {
            (Array.isArray(detail.keywords)) &&
            detail.keywords.map((keyword, index) => {
              if (keyword.includes(NEWS_TYPES_CTK.HEADLINE)) {
                return (
                  <div className='detail-headline' key={index}>
                    Headline
                  </div>
                )
              }
              if (keyword.includes(NEWS_TYPES_CTK.PHOTO)) {
                return (
                  <div className='glyphicon glyphicon-camera detail-photo' title='Zpráva obsahuje fotografii' key={index}/>
                )
              }
              if (keyword.includes(NEWS_TYPES_CTK.FLASH)) {
                return (
                  <div className='detail-flash' key={index}>
                    Fleš
                  </div>
                )
              }
              if (keyword.includes(NEWS_TYPES_CTK.PROTEXT)) {
                return (
                  <div className='detail-protext' key={index}>
                    Protext
                  </div>
                )
              }
              if (keyword.includes(NEWS_TYPES_CTK.ADVICE)) {
                return (
                  <div className='detail-avizo' key={index}>
                    Avízo
                  </div>
                )
              }
              if (keyword.includes(NEWS_TYPES_CTK.VERSION)) {
                return (
                  <div className='detail-verze' key={index}>
                    { keyword.toLowerCase() }
                  </div>
                )
              }
            })
          }
          <h1>
            <Highlightable highlighted={detail.title_highlighted} normal={detail.title}/>
          </h1>
          {
            (Array.isArray(detail.newsline) && detail.newsline.length)
              ? detail.newsline.map((newsline, index) => (
                <div className='newsline' key={index}>
                  { newsline }
                </div>
              ))
              : ''
          }
          <div dangerouslySetInnerHTML={{ __html: detail.text_highlighted ? detail.text_highlighted : detail.text }}/>
          {
            isFetchingRelated && <div className='relatedload'/>
          }
          {
            related.length > 0 && <NewsDetailRelated related={related} loadDetail={loadDetail}/>
          }
          {
            (isFetchingRelated === false && isFetchingDetail === false && related.length <= 0) && (
              <div className='rel-non-exist'>
                Nebyly nalezeny žádné související zprávy
              </div>
            )
          }
          <table className='table table-striped detailinfo'>
            <thead>
              <tr>
                <th colSpan={5}>
                  Info
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className='col-md-3'>
                  <strong>
                    Priorita:
                  </strong>
                </td>
                <td className='col-md-2'>
                  { detail.priority }
                </td>
                <td className='col-md-2'/>
                <td className='col-md-2'>
                  <strong>
                    Servis:
                  </strong>
                </td>
                <td className='col-md-3'>
                  { detail.desk && DESKS_CTK[detail.desk] ? DESKS_CTK[detail.desk] : detail.desk }
                </td>
              </tr>
              <tr>
                <td className='col-md-3'>
                  <strong>
                    Id:
                  </strong>
                </td>
                <td className='col-md-2'>
                  { detail.newsitem_id }
                </td>
                <td className='col-md-2'/>
                <td className='col-md-2'/>
                <td className='col-md-3'/>
              </tr>
              <tr>
                <td colSpan={1}>
                  <strong>
                    Seznam kategorií:
                  </strong>
                </td>
                <td colSpan={4}>
                  {
                    (detail.categories || []).map((category, index) => (
                      <span key={index}>
                        { CATEGORIES_CTK[category] ? CATEGORIES_CTK[category] : category }
                        { (index + 1 < detail.categories.length) ? ', ' : '' }
                      </span>
                    ))
                  }
                </td>
              </tr>
              <tr>
                <td colSpan={1}>
                  <strong>
                    Seznam klíč. slov:
                  </strong>
                </td>
                <td colSpan={4}>
                  {
                    (detail.keywords || []).map((keyword, index) => (
                      <span key={index}>
                        { keyword }
                        { (index + 1 < detail.keywords.length) ? ', ' : '' }
                      </span>
                    ))
                  }
                </td>
              </tr>
            </tbody>
          </table>
          {
            (detail.exports && detail.exports.length > 0) &&
            <ExportLog dispatch={this.props.dispatch} exports={detail.exports} news={detail}/>
          }
          <Modal dispatch={this.props.dispatch} {...modal}/>
        </div>
      </div>
    )
  }
}

CtkDetail.propTypes = {
  news: PropTypes.object,
  layout: PropTypes.object,
  modal: PropTypes.object,
  exports: PropTypes.object
}

function mapStateToProps (state) {
  const {
    news,
    layout,
    modal,
    exports
  } = state

  return {
    news,
    layout,
    modal,
    exports
  }
}

export default connect(mapStateToProps)(CtkDetail)
