import { combineReducers } from 'redux'
import entities from './entities'
import news from './news'
import layout from './layout'
import queryFilter from './queryFilter'
import user from './user'
import modal from './modal'
import exports from './exports'

export default combineReducers({
  user,
  entities,
  exports,
  news,
  layout,
  queryFilter,
  modal
})
