import fetch from 'isomorphic-fetch'
import { getApiUrl } from '../utils/News'
import openPopup from '../utils/Popup'
import { getRequestParams } from '../utils/RequestFactory'
import { ACTIONS } from '../constants/User'

export function loggedOut () {
  return {
    type: ACTIONS.USER_LOGGED_OUT
  }
}

let loginPopup
let popupCheckTimer
let reduxDispatch

function checkPopupState (popup, dispatch) {
  if (!popup) {
    return
  }
  if (popup.closed) {
    return dispatch({
      type: ACTIONS.USER_AUTH_CANCELED,
      errors: 'Authentication was cancelled.'
    })
  } else {
    popupCheckTimer = setTimeout(() => {
      checkPopupState(popup, dispatch)
    }, 1000)
  }
}

function startLogin (popup, dispatch) {
  dispatch({
    type: ACTIONS.USER_AUTH_START
  })
  return checkPopupState(popup, dispatch)
}

export function requestValidation (dispatch) {
  const url = getApiUrl() + '/login/validate'
  const params = Object.assign({}, getRequestParams(url), {
    headers: {
      Accept: 'application/json'
    }
  })
  dispatch({
    type: ACTIONS.USER_AUTH_VALIDATING
  })
  return fetch(url, params)
    .then(response => response.json())
    .then(json => {
      dispatch({
        type: ACTIONS.USER_AUTH_SUCCESS,
        payload: json
      })
    })
    .catch(errors => {
      dispatch({
        type: ACTIONS.USER_AUTH_FAILED,
        errors: errors
      })
    })
}

function getLoginWindowPath () {
  return (getApiUrl() + '/login/validate#capka/login')
}

function receivePopupMessage (event) {
  if (event.data === 'user logged in') {
    event.target.removeEventListener('message', receivePopupMessage)
    clearTimeout(popupCheckTimer)
    loginPopup.close()
    return requestValidation(reduxDispatch)
  }
}

export function login () {
  return (dispatch) => {
    reduxDispatch = dispatch
    const name = 'capka-login'
    const url = getApiUrl() + '/login?do=googleLogin-open&returnUri=' + encodeURIComponent(getLoginWindowPath())
    loginPopup = openPopup('google', url, name)
    if (typeof window !== 'undefined') {
      window.addEventListener('message', receivePopupMessage, false)
    }
    return startLogin(loginPopup, dispatch)
  }
}
