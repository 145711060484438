import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import LoginScreen from './LoginScreen'
import Agency from './Agency'
import { AGENCIES } from '../../constants/News'
import '../../css/bootstrap.css'
import '../../css/main.css'
import '../../css/lib/common.css'

class App extends Component {
  render () {
    const {
      user,
      agency,
      queryFilter,
      items,
      entities
    } = this.props

    if (user.isLoggedIn) {
      return <Agency
        agency={agency}
        entities={entities}
        items={items}
        queryFilter={queryFilter}
        related={agency === AGENCIES.CTK}
      />
    } else {
      return (
        <LoginScreen/>
      )
    }
  }
}

App.propTypes = {
  agency: PropTypes.string.isRequired,
  entities: PropTypes.object.isRequired,
  items: PropTypes.array.isRequired,
  queryFilter: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired
}

function mapStateToProps (state) {
  const {
    entities,
    news: {
      agency,
      items
    },
    queryFilter,
    user
  } = state

  return {
    agency,
    entities: entities[agency].news,
    items: items[agency],
    queryFilter: queryFilter.toJS()[agency],
    user
  }
}

export default connect(mapStateToProps)(App)
