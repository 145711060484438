import { ACTIONS } from '../constants/QueryFilter'
import { MERGE_STYLES } from '../constants/News'
import { requestNewsListIfNeeded } from './news'

export function setValue (filter, value, syncNewData = true) {
  return (dispatch) => {
    dispatch({
      type: ACTIONS.SET_VALUE,
      filter: filter,
      value: value
    })
    if (syncNewData) {
      dispatch(requestNewsListIfNeeded(MERGE_STYLES.REPLACE))
    }
  }
}

export function setChecked (filters, checked = true) {
  return {
    type: ACTIONS.SET_CHECKED,
    filters: filters,
    checked: checked
  }
}

export default {
  setValue
}
