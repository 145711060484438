import { ACTIONS } from '../constants/User'

function getInitialState () {
  return {
    startup: true,
    isLoggedIn: false,
    authInProgress: false,
    data: null,
    token: null,
    errors: null
  }
}

export default function user (state = getInitialState(), action) {
  switch (action.type) {
    case ACTIONS.USER_AUTH_START:
      return Object.assign({}, state, {
        isLoggedIn: false,
        authInProgress: true,
        token: null,
        error: null
      })
    case ACTIONS.USER_AUTH_VALIDATING:
      return Object.assign({}, state, {
        authInProgress: true
      })
    case ACTIONS.USER_AUTH_SUCCESS:
      return Object.assign({}, state, {
        isLoggedIn: true,
        authInProgress: false,
        token: action.payload.access_token,
        data: action.payload,
        error: null,
        startup: false
      })
    case ACTIONS.USER_AUTH_CANCELED:
    case ACTIONS.USER_AUTH_FAILED:
      return Object.assign({}, state, {
        isLoggedIn: false,
        authInProgress: false,
        token: null,
        errors: action.errors,
        startup: false
      })
    case ACTIONS.USER_LOGGED_OUT:
      return Object.assign({}, state, {
        isLoggedIn: false,
        authInProgress: false,
        data: null,
        token: null,
        errors: null
      })
    default:
      return state
  }
}
