import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { toggleFlasher } from '../../actions/news'
import { connect } from 'react-redux'
import FlasherSound from './FlasherSound'

class Flasher extends Component {
  handleFlasherChange = () => {
    const {
      dispatch
    } = this.props

    dispatch(toggleFlasher())
  }

  render () {
    const {
      flasher
    } = this.props

    return (
      <div className='col-sm-2'>
        <div className='col-sm-12 search-text-title'>
          Flashovat:
        </div>
        <div className='col-sm-6'>
          <input
            type='checkbox'
            name='flash'
            onChange={this.handleFlasherChange}
            className='head-flash'
            checked={flasher}
          />
        </div>
        <FlasherSound/>
      </div>
    )
  }
}

Flasher.propTypes = {
  dispatch: PropTypes.func.isRequired,
  flasher: PropTypes.bool.isRequired
}

function mapStateToProps (state) {
  const {
    flasher
  } = state.news
  return {
    flasher
  }
}

export default connect(mapStateToProps)(Flasher)
